import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { FormsDataServiceInterface } from '@discoverer/dynamic-forms/dyn-forms/services';
import Form from './applicant-attachment.form.json';
import { ApplicantAttachmentService } from '../../../services/apis/applicant-attachment.service';
import { IApplicantAttachmentDto } from '../../../../../models/dtos/applicant-attachment.dto';

@Injectable()
export class ApplicantAttachmentFormService implements FormsDataServiceInterface {
    constructor(private _applicantAttachmentService: ApplicantAttachmentService) {
    }
    public getFormByKey(key: string) {
        const form = Form.filter(f => f.Key === key)[0];
        const obj = {
            Key: key,
            Title: form.Title,
            Configuration: JSON.stringify(form.Configuration)
        };
        return of(obj).delay(1);
    }

    postFormData(object: any): Observable<any> {

        if (object.applicantId && object.attachmentsIds) {
            {
                var applicantAttachments = object.attachmentsIds.map(id => {
                    return {
                        applicantId: object.applicantId,
                        attachmentId: id
                    } as IApplicantAttachmentDto;
                }) as IApplicantAttachmentDto[];

                return this._applicantAttachmentService.create(object.applicantId, applicantAttachments);
            }
        }
    }
}

