import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { FormsDataServiceInterface } from '@discoverer/dynamic-forms/dyn-forms/services';
import 'rxjs/add/operator/delay';
import Form from './new-applicant-import-link.form.json';


@Injectable()
export class NewImportLinkFormService implements FormsDataServiceInterface {
    constructor() {
    }
    public getFormByKey(key: string) {
        const form = Form.filter(f => f.Key === key)[0];
        const obj = {
            Key: key,
            Title: form.Title,
            Configuration: JSON.stringify(form.Configuration)
        };
        return of(obj).delay(1);
    }
    postFormData(): Observable<any> {
        return of();
    }


}

