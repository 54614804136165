export const environment = {
  isDevelopment: false,
  production: true,
  FRONTEND_RULE: "ats.dev.jo.capella.io",
  APIEndpoint: 'https://ignite.dev.jo.capella.io/',
  ATS: {
    GraphQlURL: 'https://ats-graphql.ignite.dev.jo.capella.io/v1/graphql'
  },
  baseURL: 'https://ignite.dev.jo.capella.io/',
  VERSION: require('../../package.json').version,
  auth: {
    API: 'https://apps.dev.jo.capella.io/',
    logoutURL: '',
    loginURL: 'https://apps.dev.jo.capella.io/',
    productKey: 'ATS',
    publicAccessToken: '25d314d007a13b3b2e03e48110f704ba8628601e-1706115598422-eyJraWQiOiIxIiwidHlwIjoiSldUIiwiYWxnIjoiSFMyNTYifQ.eyJzdWIiOiJhZG1pbkBjYXBlbGxhLmlvIiwiQWNjb3VudElkIjoxLCJGdWxsTmFtZSI6IlN5c3RlbSBBZG1pbmlzdHJhdG9yIiwiUm9sZXMiOlsiYXRzOmFkbWluIiwiYXRzOnJlcG9ydHMiLCJjY3JtOmFkbWluIiwiY2NybTpyZXBvcnRzIiwiZG9jcGlwZWxpbmU6YWRtaW4iLCJpcDpyZXBvcnRzIiwib2JpOmFkbWluIiwib2JpZGl2OmFkbWluIiwib2JpZGl2OnVzZXIiLCJvYmk6aW5mcmEiLCJvYmlzdGc6YWRtaW4iLCJvYmlzdGc6dXNlciIsInByY3RsOmFkbWluIiwicHJjdGw6dXNlciIsInFhLWVudGVycHJpc2U6YWRtaW4iLCJxY2tib3Q6YWRtaW4iLCJ0aW1ldHJhY2tlcjphZG1pbiIsInZtZG06YWRtaW4iLCJ2bWRtOnVzZXIiXSwiaWF0IjoxNzA2MTE1NTk4fQ.Ltfa5PxGtM4ZyKFqZ-Je79o22zOZgzWyiuspglYaR3Q'
  }
};
