import { Component, ViewChild, ChangeDetectorRef, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { OptionListDataServiceInterface, OptionDataServiceInterface } from '../../../../../../discoverer/dynamic-forms/option-lists/shared-services/data-service';
import { FormsDataServiceInterface } from '../../../../../../discoverer/dynamic-forms/dyn-forms/services/forms-data-service.interface';
import { HasuraOptionListService } from '../../../../../../discoverer/dynamic-forms/option-lists/shared-services/hasura-option-list-service';
import { HasuraOptionService } from '../../../../../../discoverer/dynamic-forms/option-lists/shared-services/hasura-option-service';
import { DynFormComponent } from '@discoverer/dynamic-forms/dyn-forms/dyn-form/dyn-form.component';
import { environment } from '@env/environment';
import { NewImportLinkFormService } from './new-applicant-import-link-from.service';

@Component({
    // tslint:disable-next-line:component-selector
    selector: 'new-applicant-import-link-dialog',
    templateUrl: './new-applicant-import-link-dialog.html',
    styleUrls: ['./new-applicant-import-link-dialog.scss'],
    providers: [
        { provide: OptionListDataServiceInterface, useClass: HasuraOptionListService },
        { provide: OptionDataServiceInterface, useClass: HasuraOptionService },
        { provide: FormsDataServiceInterface, useClass: NewImportLinkFormService }
    ]
})
export class NewApplicantImportLinkDialogComponent implements OnInit {

    public isLoading = false;
    @ViewChild('dynForm') dynForm: DynFormComponent;

    public model: any = {};

    constructor(
        private cdr: ChangeDetectorRef,
        public dialogRef: MatDialogRef<NewApplicantImportLinkDialogComponent>) { }


    async ngOnInit() {
        this.model.baseUrl = environment.FRONTEND_RULE
    }

    get form() {
        return this.dynForm.form;
    }

    close() {
        this.dialogRef.close();
    }

    onSubmit(event) {
        this.isLoading = false;
        if (event) {
            this.dialogRef.close(event);
        }
        else {
            this.isLoading = false;
        }
    }
}

