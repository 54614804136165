<mat-icon class="notifications-button" [matBadge]="unseenNotificationsCount" matBadgeColor="warn"
    [matMenuTriggerFor]="menu">
    <span class="material-icons">
        notifications
    </span>
</mat-icon>
<mat-menu #menu="matMenu" class="notifications-menu">
    <div class="row">
        <span class="mark-as-seen" (click)="markAllAsSeen()">Mark all as seen</span>
    </div>
    <div *ngIf="!notifications?.length">
        <span class="no-notifications">No notifications to display</span>
    </div>
    <div *ngIf="notifications?.length">
        <button class="notification" [ngClass]="{'unseen' : !notification.isSeen}"
            (click)="onNotificationClick(notification)"
            *ngFor="let notification of notifications | sort:'desc':'createdOn'" mat-menu-item>
            <div class="row notification-header">
                <span class="date">
                    {{notification.createdOn | date:'MM/dd/yyyy HH:mm:ss'}}
                </span>
            </div>
            <div class="row notification-body">
                <div *ngIf="notification.badgeContent" class="badge"> {{notification.badgeContent}} </div>
                <span class="body"> {{notification.body}} </span>
            </div>
        </button>

    </div>
</mat-menu>