import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import gql from 'graphql-tag';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';


@Injectable()
export class AssetService {
  constructor(public apollo: Apollo) { }
  public getFormByKey(key: string): Observable<any> {
    const query = gql`
        query FormByKey(
            $key: String
        ) {
            Forms(where: {Key: {_eq: $key}}) {
              Configuration
              Title
              Key
            }
        }
      `;
    return this.apollo.query<any>({
      query,
      variables: {
        key
      }
    }).pipe(
      map(x => x.data.Forms[0])
    );
  }

  getNextSerialNumber(): Observable<any> {
    const query = gql`
        mutation {
            insert_AssetSerialNumbers(objects: {}) {
              returning {
                SerialNumber
              }
            }
          }
          `;
    return this.apollo.query<any>({
      query
    }).pipe(map(x => x.data.insert_AssetSerialNumbers.returning[0].SerialNumber));
  }
}
