<div class="filters-container">
    <select-facet *ngIf="currentTab && currentTab.serviceUrl"
        class="facet"
        [title]="'Hiring Stage'"
        [facetKey]="'Status'"
        [queryService]="reportPersist.mainQueryService"
        [serviceUrl]="currentTab.serviceUrl"
        [singleMode]="true"
        [displayValues]="statusList">
    </select-facet>
</div>
