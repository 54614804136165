export interface ITechnicalInterview {
    id: number;
    applicantId: number;
    interviewDate?: Date;
    technicalLevel?: number;
    examScore?: number;
    notes?: string;
    examResult?: number;
}
export class TechnicalInterview {
    id: number;
    applicantId: number;
    interviewDate?: Date;
    technicalLevel?: number;
    examScore?: number;
    notes?: string;
    interviewType = 'Technical';
    examResult?: number;
    logListId?: number;
    constructor(obj?: ITechnicalInterview) {
        this.applicantId = obj && obj.applicantId || null;
        this.interviewDate = obj && obj.interviewDate || null;
        this.technicalLevel = obj && obj.technicalLevel || null;
        this.examScore = obj && obj.examScore || null;
        this.notes = obj && obj.notes || '';
        this.id = obj && obj.id || 0;
        this.examResult = obj && obj.examResult || null;
    }

}

