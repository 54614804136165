import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { mockApiServices } from './mock-api';
import { AbstractNavigationItemsService, AppCoreModule } from '@discoverer/app-core';
import { MainNavComponent } from './ATS/main-nav/main-nav.component'
import { ReportsModule } from './ATS/components/reports/reports.module';
import { GraphQLModule } from './graphql.module';
import { environment } from '@env/environment';
import { GraphQlOptionDataServiceInterface } from '@discoverer/dynamic-forms/option-lists/shared-services/data-service';
import { GraphQueryOptionService } from './ATS/components/reports/services/graph-query-option.service';
import { NavigationItemsService } from './shared/navigation-items-service';



@NgModule({
    declarations: [
        AppComponent,
        MainNavComponent
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        AppRoutingModule,
        ReportsModule,
        GraphQLModule.forRoot(environment.ATS.GraphQlURL, ['ats']),

        // Core module of your application
        AppCoreModule.forRoot({
            provide: AbstractNavigationItemsService,
            useClass: NavigationItemsService
        }),

    ],
    providers: [
        { provide: GraphQlOptionDataServiceInterface, useClass: GraphQueryOptionService },

    ],
    bootstrap: [AppComponent]
})
export class AppModule {
}
