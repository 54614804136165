import { ComponentType } from '@angular/cdk/portal';
import { MatDialog } from '@angular/material/dialog';
import { DialogsManagerService } from '@discoverer/dynamic-reports/dialogs';
import { BaseController, DataFormattingService, DataSourceContext } from '@discoverer/core';
import { orderBy, SortDescriptor } from '@progress/kendo-data-query';

export interface IRelatedRow {
    Id: number;
}

export interface IRelatedRowConstructor<T extends IRelatedRow> {
    // tslint:disable-next-line:callable-types
    new(data: any): T;
}

export class CommonRelatedEntityController<T extends IRelatedRow> extends BaseController {
    currentRow: T;
    public columns;
    public gridView;
    public context: DataSourceContext;
    public filters;
    public fs = new DataFormattingService();
    sorts = [{
        sortField: this.defaultSortField,
        dir: this.defaulltSortDir
    }];
    public sort: SortDescriptor[] = [
        {
            field: this.defaultSortField,
            dir: this.defaulltSortDir
        }
    ];
    constructor(
        protected type: IRelatedRowConstructor<T>,
        public dialogService: DialogsManagerService,
        public dialog: MatDialog,
        public appId,
        public tabId,
        public componentType,
        public requiredColumns: string[],
        public defaultSortField,
        public defaulltSortDir
    ) {
        super();
    }
    public contextChanged(context: DataSourceContext) {
        if (!!context && !!context.data) {
            this.currentRow = context.data[0];
            this.context = context;
            this.columns = Object.keys(context.columnDictionary).map(x => context.columnDictionary[x])
                .filter(x => this.requiredColumns.includes(x.fieldName))
                .sort((a, b) => a.displayOrder - b.displayOrder);
            this.loadData();
        }
    }
    public async editDataItem(dataItem) {
        const result = await this.openDialog(this.componentType, dataItem);
        setTimeout(() => {
            this.refresh();
        }, 500);
    }

    public refresh() {
        this.context.dataService.refresh();
    }

    public sortChange(sort: SortDescriptor[]): void {
        this.sort = sort;
        this.loadData();
    }

    private loadData(): void {
        this.gridView = {
            data: orderBy(this.context.data, this.sort),
            total: this.context.data.length
        };
    }

    // tslint:disable-next-line:no-shadowed-variable
    public async openDialog<T>(componentType: ComponentType<T>, data: any) {
        const dialogRef = this.dialog.open(componentType, {
            data: new this.type(data),
            width: '960px',
            panelClass: 'white-class-details-dialog',
        });
        return dialogRef.afterClosed()
            .toPromise()
            .then(result => {
                return Promise.resolve(result);
            });
    }

}
