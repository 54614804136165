import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PageTitleComponent } from './page-title/page-title.component';
import { MatIconModule } from '@angular/material/icon';



const components = [
  PageTitleComponent
];

@NgModule({
  declarations: [
    components
  ],
  imports: [
    CommonModule,
    MatIconModule
  ],
  exports: [
    components
  ]

})
export class WidgetsModule {
}
