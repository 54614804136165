import { Inject, Injectable, InjectionToken } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { FormsDataServiceInterface } from '@discoverer/dynamic-forms/dyn-forms/services';
import { IDynForm } from '@discoverer/dynamic-forms/dyn-forms/common/models';
import { ITask } from '../models/task';
import { environment } from '@env/environment';

export class WorkflowFormsConfig {
    formControllerURL: string;
}

export const WORKFLOW_FORMS_CONFIG = new InjectionToken<WorkflowFormsConfig>('');


@Injectable()
export class HttpFormsDataService implements FormsDataServiceInterface {

    constructor(
        private http: HttpClient,
        @Inject(WORKFLOW_FORMS_CONFIG) private workflowFormsConfig: WorkflowFormsConfig
    ) { }

    public getFormByKey(key: string) {
        return this.http.get(`${this.workflowFormsConfig.formControllerURL}/${key}`);
    }

    public getFormsByDirectory(directory: string) {
        return this.http.get<IDynForm[]>(`${this.workflowFormsConfig.formControllerURL}?directory=${directory}`);
    }

    public postFormData(object: ITask) {
        Object.keys(object).forEach(key => {
            if (object[key] instanceof Date) {
                object[key] = new Date(object[key]).toUTCString();
            }
        });
        const requestObject = {
            Subject: object.Subject,
            Description: object.Description,
            RelatedToId: object.RelatedToId,
            Response: { type: 'form', formKey: object.FormKey, data: object },
            AssigneeId: object.AssigneeId,
            DueDate: object.DueDate,
            CreatedById: object.CreatedById,
            AppBaseUrl: environment.baseURL
        };
        return this.http.post(`${object.TargetUrl}`, requestObject);
    }
}

