<disco-details #details appId="ats" tabId="tasks" [isDeletable]="false" (context)="setContext($event);" [formServicePath]="formServicePath">
  <ng-template #headerTemplate let-context>
    <div class="header title-header">
      <div>
        <div style="color: var(--dark-600); text-transform: uppercase; font-size: 14px;">Task</div>
        <div class="col">
          <div class="row">
            <h1>{{currentRow.Subject}}</h1>
            <div class="link" *ngIf="currentRow.RelatedTo" (click)="navigateToEmployeePage()">
              <span>{{currentRow.RelatedTo}}</span>
              <span class="material-icons">open_in_new</span>
            </div>
          </div>
          <button *ngIf="!currentRow.AssigneeId" class="assign-button" mat-button (click)="assignToMe()">Assign to
            me</button>
        </div>

      </div>
      <div class="header-buttons">
        <div *ngIf="isPending && assignedToCurrentUser" class="action-buttons">
          <button [disabled]="isLoading" mat-button *ngFor="let option of options"
            (click)="respondToRequest(option)">{{option}}</button>
          <mat-spinner diameter=20 *ngIf="isLoading"></mat-spinner>
        </div>
      </div>
    </div>
  </ng-template>
  <ng-template #bodyTemplate let-context>
    <mat-card class="card main-card">
      <div class="row" style="justify-content: space-between;">
        <app-dyn-task class="requested-task" *ngIf="originalRequest?.type == 'form'" [key]="originalRequest.formKey"
          [data]="originalRequest.data" [isEditable]="false" [showTitle]="false">
        </app-dyn-task>
        <app-task-response-chain class="response-chain" #responseChain [wfRefId]="currentRow.WFReferenceId" [appId]="appId">
        </app-task-response-chain>
      </div>
    </mat-card>
  </ng-template>
</disco-details>