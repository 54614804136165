
export interface INote {
    id: number;
    notesListId?: number;
    body: string;
    createdBy:  { id: number, name: string, email: string };
    createdOn: Date;
    attachment?: any;
    fileName?: string;
    attachments?: INoteAttachment[];
    type?: 'Call' | 'Email' | 'Note';
    notification: INotifcation;
}

export interface INotifcation {
    badgeContent: string;
    body: string;
    noteId: number;
    url: string;
}

export interface INoteAttachment {
    id: number;
    fileName: string;
    filePath: string;
    noteId: number;
}
export class Note {
    id: number;
    notesListId?: number;
    body: string;
    createdBy: { id: number, name: string, email: string };
    createdById: number;
    createdOn: Date;
    attachment?: any;
    fileName?: string;
    attachments?: INoteAttachment[];
    type?: 'Call' | 'Email' | 'Note';
    constructor(obj?: INote) {
        this.id = obj && obj.id || 0;
        this.notesListId = obj && obj.notesListId || null;
        this.body = obj && obj.body || '';
        this.createdBy = obj && obj.createdBy || null;
        this.createdOn = obj && obj.createdOn || null;
        this.attachment = obj && obj.attachment || null;
        this.fileName = obj && obj.fileName || null;
        this.attachments = obj && obj.attachments || null;
        this.type = obj && obj.type || null;
    }
}

