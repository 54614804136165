import { Injectable } from '@angular/core';
import { FormsDataServiceInterface } from '../../../../../../discoverer/dynamic-forms/dyn-forms/services/forms-data-service.interface';
import { ApplicantTagService } from '../../../services/apis/applicant-tag.service';
import Form from './applicant-tags.form.json';
import { Applicant } from '../../../../../models/applicant';
import { IApplicantTagDto } from '../../../../../models/dtos/applicant.tag.dto';
import { Observable, of } from 'rxjs';



@Injectable()
export class ApplicantTagsFromService implements FormsDataServiceInterface {
    constructor(private _applicantTagService: ApplicantTagService) {
    }
    public getFormByKey(key: string) {
        const form = Form.filter(f => f.Key === key)[0];
        const obj = {
            Key: key,
            Title: form.Title,
            Configuration: JSON.stringify(form.Configuration)
        };
        return of(obj).delay(1);
    }

    postFormData(object: any): Observable<any> {
        return this.updateTags(object);
    }
    public updateTags(object: Applicant): Observable<any> {
        let response;
        if (object.applicantId) {
            var list = object.tagsIds.map(id => {
                return {
                    applicantId: object.applicantId,
                    tagId: id
                } as IApplicantTagDto;
            }) as IApplicantTagDto[];

            return this._applicantTagService.create(object.applicantId, list);
        }
        return response;
    }
}

