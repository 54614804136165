import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { FormsDataServiceInterface } from '@discoverer/dynamic-forms/dyn-forms/services';
import 'rxjs/add/operator/delay';
import { ApplicantService } from '../../services/apis/applicant.service';
import Form from './import-applicant.form.json';
import { IImportApplicantDto } from '../../../../models/dtos/import-applicant.dto';


@Injectable()
export class ImportApplicantFormService implements FormsDataServiceInterface {
    constructor(private _applicantApiService: ApplicantService) {
    }
    public getFormByKey(key: string) {
        const form = Form.filter(f => f.Key === key)[0];
        const obj = {
            Key: key,
            Title: form.Title,
            Configuration: JSON.stringify(form.Configuration)
        };
        return of(obj).delay(1);
    }
    postFormData(applicant: any): Observable<any> {

        let isPublicApplications = applicant.isPublicApplication;
        let resumeFileIds: number[] = isPublicApplications ? [applicant.resumeFileId] : applicant.resumeFileIds;

        const applicants: IImportApplicantDto[] = resumeFileIds.map((resumeFileId) => {
            return {
                applicationDate: applicant.applicationDate,
                jobAppliedForId: applicant.jobAppliedForId,
                sourceId: applicant.sourceId,
                resumeFileId: resumeFileId,
                isPublicApplication: applicant.isPublicApplication,
            };
        });

        return this._applicantApiService.importList(isPublicApplications, applicants);
    }
}

