import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import gql from 'graphql-tag';
import { map } from 'rxjs/operators';

@Injectable()
export class TaskService {
    constructor(
        public apollo: Apollo
    ) { }

    public assignTask(taskId: number, assigneeId: number): Promise<number> {
        const query = gql`mutation ($assigneeId: Int , $id: Int ) {
            update_ignt_workflow_Requests(where: {Id: {_eq: $id}}, _set: {AssigneeId: $assigneeId}) {
              affected_rows
            }
          }
          `;
        return this.apollo.query<any>({
            query,
            variables: {
                id: taskId, assigneeId
            }
        }).pipe(map(x => x.data.update_ignt_workflow_Requests.affected_rows)).toPromise();
    }
}
