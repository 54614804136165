import { Component, ViewEncapsulation, Optional, ViewChild } from '@angular/core';
import { Applicant } from '@app/ATS/models/applicant';
import { MatDialogRef } from '@angular/material/dialog';
import { OptionListDataServiceInterface, OptionDataServiceInterface } from '@discoverer/dynamic-forms/option-lists/shared-services/data-service';
import { FormsDataServiceInterface } from '@discoverer/dynamic-forms/dyn-forms/services';
import { HasuraOptionService } from '@discoverer/dynamic-forms/option-lists/shared-services/hasura-option-service';
import { HasuraOptionListService } from '@discoverer/dynamic-forms/option-lists/shared-services/hasura-option-list-service';
import { FilesServiceInterface } from '@discoverer/dynamic-forms/dyn-forms/dyn-upload/files-service.interface';
import { UserDataService, UserDataServiceInterface } from '@discoverer/app-core';
import { EsearchSettingsService } from '@discoverer/core';
import { ActivatedRoute } from '@angular/router';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { AtsPublicFilesService } from '../../services/public-files.service';
import { ImportApplicantFormService } from './import-applicant-form.service';

@Component({
    selector: 'import-applicant',
    templateUrl: './import-applicant.component.html',
    styleUrls: ['./import-applicant.component.scss'],
    encapsulation: ViewEncapsulation.None,
    providers: [
        { provide: OptionListDataServiceInterface, useClass: HasuraOptionListService },
        { provide: OptionDataServiceInterface, useClass: HasuraOptionService },
        { provide: FormsDataServiceInterface, useClass: ImportApplicantFormService },
        { provide: UserDataServiceInterface, useExisting: UserDataService },
        { provide: FilesServiceInterface, useClass: AtsPublicFilesService },
        EsearchSettingsService
    ]
})

export class ImportApplicantComponent {
    @ViewChild('dynForm', { static: false }) dynForm;
    constructor(
        private activatedRoute: ActivatedRoute,
        private userDataService: UserDataService,
        @Optional() public dialogRef: MatDialogRef<ImportApplicantComponent>) {
    }
    public data: any = {};
    public dataChanged = false;
    public appId = 'ats';
    public tabId = 'applicants';
    public details = new Applicant();
    public id: any;
    public hasApplied = false;
    public isLoading = false;
    private _unsubscribeAll$: Subject<any> = new Subject<any>();

    public async ngOnInit() {

        //this.hasApplied = true;
        this.data.applicationDate = new Date();
        this.data.isImportApplicant = true;

        this.activatedRoute.paramMap.pipe(takeUntil(this._unsubscribeAll$)).subscribe(async params => {
            const jobAppliedForId = +params.get("jobAppliedForId");
            this.data.jobAppliedForId = +jobAppliedForId;
            this.data.isPublicApplication = true;
        })

        this.userDataService.loggedInUser.subscribe(x => {
            if (x && x.id) {
                this.data.isPublic = false;
            }
        });
    }

    public onSubmit(event) {
        if (event) {
            this.hasApplied = true;
        }
    }
    public save() {


        this.isLoading = true;

        if (!this.dynForm.form.valid) {
            this.isLoading = false;
        }

        this.dynForm.submit();
    }
    public close() {
        this.dialogRef.close({ reload: this.dataChanged });
    }

    ngOnDestroy(): void {
        this._unsubscribeAll$.next(null);
        this._unsubscribeAll$.complete();
    }
}
