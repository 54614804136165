<div *ngIf="!hasApplied" style="display: flex;flex-direction: column;align-items: center;">
    <div style="width: 200px;padding-top: 8px;align-self: start;padding-left: 30px;">
        <img src="assets/images/logo/capella-logo.png">
    </div>
    <div>
        <h1 style="font-size: revert;">
            Application
        </h1>
    </div>
    <div class="form-container">
        <div class="bg">
            <app-dyn-form #dynForm class="form" [key]="'new-applicant'" [model]="data" [showTitle]="false"
                [message]="'Thank you for your application.'" [isEditMode]="true" (result)="onSubmit($event)">
            </app-dyn-form>
            <div style="display: flex;">
                <mat-spinner *ngIf="isLoading" [diameter]="30" color="primary"></mat-spinner>
                <button class="m-r-auto" mat-raised-button color="primary" [disabled]="isLoading" mat-button
                    (click)="save()">
                    Submit
                </button>
            </div>
        </div>
    </div>
</div>

<div class="user-msg-container" *ngIf="hasApplied">
    <!-- <h3 class="user-msg">Applied successfully.<br>Thank you for your interest.</h3> -->
    <section class="login-main-wrapper">
        <mat-icon class="check-circle-icon">check_circle</mat-icon>
        <div style="justify-content: center">
            <p style="font-weight: bold; font: 18px">THANK YOU</p>
        </div>
        <P style="font: 18px">Form has been successfully submitted</P>
    </section>
</div>
