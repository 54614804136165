
<div class="title">
    <span>Applicant Tags</span>
    <mat-icon class="close-button" (click)="close()">close</mat-icon>
</div>
<div class="mt-4" style="height: calc(90% - 60px); overflow-y: auto; ">
<app-dyn-form #dynForm key="applicant-tags" [model]="model" [showTitle]="false" [isEditMode]="true" 
    (result)="onSubmit($event)">
</app-dyn-form>
</div>
<div class="buttons">
    <mat-spinner *ngIf="isLoading" [diameter]="30" color="primary"></mat-spinner>
    <button mat-raised-button class="save-button" color="primary" [disabled]="isLoading" mat-button
        (click)="save()">SAVE</button>
    <button mat-raised-button mat-button (click)="close()">Cancel</button>
</div>