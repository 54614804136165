
import { JsonpClientBackend } from '@angular/common/http';
import { Injectable, OnDestroy, OnInit } from '@angular/core';
import { max, min } from 'lodash';
import { BehaviorSubject, Observable, ReplaySubject, Subject } from 'rxjs';
import { distinctUntilChanged, first, switchMap, takeUntil, tap } from 'rxjs/operators';
import { FacetFieldAccumulator } from '../classes/facet-field-accumulator.class';
import { LoadingState } from '../classes/loading-state';
import { DiscovererDataService } from './discoverer-data-service.service';
import { DiscovererQueryService } from './discoverer-query-service.service';
import { disLogger } from '../functions';

@Injectable()
export class CachedIgniteDataService<T> implements OnDestroy {

  public oData: Observable<T[]>;
  protected _sData: Subject<T[]>;

  public oFacetResults: Observable<FacetFieldAccumulator[]>;
  public oResultLength: Observable<number>;
  public oLoadingStatusResult: Observable<LoadingState>;

  protected _bsLoadingStatus$: BehaviorSubject<LoadingState>;



  protected _cache: any[] = [];
  protected _cacheRequests: { start: number, end: number, filled: boolean, requestedOn: Date }[] = [];

  protected _fetchStart: number = 0;
  protected _fetchSize: number = 0;

  protected _requestStart: number = 0;
  protected _requestSize: number = 20;
  protected _unsubscribeAll: Subject<any> = new Subject<any>();

  constructor(public dataService: DiscovererDataService<T>) {
    this._sData = new ReplaySubject(1);
    this.oData = this._sData.asObservable();

    this._bsLoadingStatus$ = new BehaviorSubject<LoadingState>({ status: "Busy" });
    this.oLoadingStatusResult = this._bsLoadingStatus$.asObservable();

    this.oFacetResults = dataService.oFacetResults;
    this.oResultLength = dataService.oResultLength;
    this.dataService.enabled = false;


    this.oResultLength.pipe(distinctUntilChanged((a, b) => a == b), tap(
      n => {
        var x = Math.min(n, 5000);
        console.log(';;; init array=' + x);
        this._cache = Array.apply(null, x ? Array(x) : []);
      }
    )).pipe(
      switchMap(x => {
        return this.dataService.oData;
      })
    ).pipe(takeUntil(this._unsubscribeAll)).subscribe(data => {
      //if ( this._changeId === this.dataService.changeId) {
      if (!data?.length) {
        this._sData.next([]);
      } else {
        var matchRequest = this._cacheRequests.find(x => x.start == this._fetchStart);
        if (!!matchRequest) {
          matchRequest.filled = true;
        }
        console.log(';;; fill data=' + this._fetchStart + '-' + (this._fetchStart + data.length) + ' found=' + !!matchRequest);
        data.forEach((v, i) => this._cache[i + this._fetchStart] = v);
        this.refresh();
      }
      //}
    });
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next(true);
  }

  public init(serviceUrl: string, queryService: DiscovererQueryService, name?: string) {
    this.dataService.init(serviceUrl, queryService, name);
    this.dataService.enabled = false;


    this.dataService.baseQueryService.oQuery.pipe(takeUntil(this._unsubscribeAll)).subscribe(s => {
      this._requestStart = 0;
      this._requestSize = 20;
      this._fetchStart = 0;
      this._fetchSize = 80;
      this._cache = [];
      this._cacheRequests = [];
      this.refresh();
    });

    this.dataService.oLoadingStatusResult.pipe(takeUntil(this._unsubscribeAll)).subscribe(state => {
      this._bsLoadingStatus$.next(state);
    });
  }
  public async initCacheLength(length: number) {
    this._cache.length = length || 0;
  }

  public setPageParams(pageNumber: number, pageSize: number) {
    this.setPageStart((pageSize * (pageNumber - 1)), pageSize);
  }

  public setPageStart(start: number, pageSize: number) {
    console.log(';;; start=' + start + ', pageSize=' + pageSize);

    this._requestStart = start;
    this._requestSize = pageSize;
  }

  public refresh() {
    
    this._fetchStart = this._requestStart < 0 ? 0 : this._requestStart;
    this._fetchSize = 80;

    //cache hit or partial hit
    let sizeOfItems = this._requestStart + this._requestSize;
    sizeOfItems = sizeOfItems > this._cache.length ? this._cache.length : sizeOfItems;
    if (sizeOfItems > 0) {
      const cacheData = this._cache.slice(this._requestStart, sizeOfItems);
      const firstNull = cacheData.findIndex(x => x == null);

      if (firstNull < 0) {
        this._sData.next(cacheData);
        return;
      } else {
        //partial cache hit
        this._fetchStart = firstNull + this._requestStart;
      }
    }

    //cache miss
    this.dataService.setPageStart(
      this._fetchStart + 1,
      this._fetchSize
    );
    this._cacheRequests.push({ start: this._fetchStart, end: this._fetchStart + this._fetchSize, filled: false, requestedOn: new Date() });
    this.dataService.refresh();

    


  }


}
