import { INote } from '../models/note.model';
import { Observable } from 'rxjs';

export abstract class NotesServiceInterface {
    constructor() {
    }
    public abstract getListNotes(listId: number): Promise<INote[]>;
    public abstract addNote(formData: FormData): Promise<INote>;
    public abstract downloadAttachment(id: number): Promise<any>;
    public abstract deleteNote(id: number): Observable<number>;
    public abstract deleteAttachment(id: number): Observable<number>;
    public abstract addNoteNotification(noteId: number, entityId: number): Promise<boolean> | boolean;
}
