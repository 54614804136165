<div *ngIf="!isLoading">
    <div class="response-container col" *ngFor="let res of taskResponseChain; let i =index">
        <div class="response-text" [ngClass]="{'pending': !res.response}">
            <div class="upper-row">
                <span>{{res.completedBy}}</span>
                <span>{{res.response?(getFormattedTime(res.completedOn) | date: 'MMM d, y, hh:mm a'):'Processing ...'}}</span>
            </div>
            <span class="subject">
                {{res.subject}}
            </span><br>
            <span>
                {{res.response?.data?.response}}
            </span>
        </div>

        <span *ngIf="i != taskResponseChain.length-1" class="material-icons-outlined down-arrow">
            south
        </span>
    </div>
</div>
<mat-spinner *ngIf="isLoading" diameter=30></mat-spinner>