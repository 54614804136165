import { Component, Optional, Inject, ViewChild, ElementRef, ViewEncapsulation, NgZone, OnInit, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot, Router } from '@angular/router';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';
import { drawDOM, exportPDF, DrawOptions, Group } from '@progress/kendo-drawing';
import { saveAs } from '@progress/kendo-file-saver';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Applicant } from '@app/ATS/models/applicant';
import { DomSanitizer, Title } from '@angular/platform-browser';
import { ApplicantInterviewFormService } from './applicant-interview-form/applicant-interview-form.service';
import { ITechnicalInterview, TechnicalInterview } from '@app/ATS/models/technical-interview';
import { SecondInterview } from '@app/ATS/models/second-interview';
import { DialogsManagerService } from '@discoverer/dynamic-reports/dialogs';
import { first } from 'rxjs/operators';
import { OptionDataServiceInterface, OptionListDataServiceInterface } from '@discoverer/dynamic-forms/option-lists/shared-services/data-service';
import { HasuraOptionListService } from '@discoverer/dynamic-forms/option-lists/shared-services/hasura-option-list-service';
import { HasuraOptionService } from '@discoverer/dynamic-forms/option-lists/shared-services/hasura-option-service';
import { UserDataServiceInterface } from '@discoverer/attachments/services/user-data-service-interface.service';
import { NotesServiceInterface } from '@discoverer/attachments/services/notes-service-interface.service';
import { NotesService } from '@discoverer/attachments/services/notes.service';
import { FilesServiceInterface } from '@discoverer/dynamic-forms/dyn-forms/dyn-upload/files-service.interface';
import { UserDataService } from '@discoverer/app-core';
import { buildFacetFilter, buildQueryFromParams, DataSourceContext, DEFAULT_REPORT_TITLE, DiscovererQueryService, EsearchSettingsService, TabSettingsService } from '@discoverer/core';
import { DISCO_FORM_SERVICE_URL, HttpFormsDataService } from '@discoverer/dynamic-forms/dyn-forms/services';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { DiscoDetailsComponent } from '@discoverer/dynamic-reports';
import { CommonDetailsController } from '@app/ATS/components/reports/common/common-details-controller';
import { ApplicantService } from '@app/ATS/components/reports/services/apis/applicant.service';
import { ISecondInterview } from '@app/ATS/models/second-interview';
import { IApplicantInterviewDto } from '../../../../models/dtos/applicant-interview.dto';
import { ApplicantInterviewService } from '../../services/apis/applicant-interview.service';



@Component({
    selector: 'app-applicant-details',
    templateUrl: './applicant-details.component.html',
    styleUrls: ['../../reports.component.scss', './applicant-details.component.scss'],
    encapsulation: ViewEncapsulation.None,
    providers: [
        { provide: OptionListDataServiceInterface, useClass: HasuraOptionListService },
        { provide: OptionDataServiceInterface, useClass: HasuraOptionService },
        { provide: UserDataServiceInterface, useExisting: UserDataService },
        { provide: NotesServiceInterface, useExisting: NotesService },
        { provide: HttpFormsDataService, useClass: HttpFormsDataService, deps: [HttpClient] },
        { provide: DISCO_FORM_SERVICE_URL, useValue: `api/es/form/ats/applicants` }
    ]
})
export class ApplicantDetailsComponent extends CommonDetailsController<Applicant> implements OnInit {
    @ViewChild('pdfContainer', { static: false }) pdfContainer: ElementRef;
    @ViewChild('details') details: DiscoDetailsComponent;
    formServicePath = `/api/es/form/ats/applicants`;

    resumeFileUrl = null;
    iframeResumeUrl = null;
    isGeneratePDF = false;
    appId = 'ats';
    tabId = 'applicants';
    requestId = null;
    start = 0;
    historyFilters = [];
    columnList = [
        'ApplicantId',
        'Name'
    ];
    rowDetails = new Applicant();
    technicalInterviewDetails = new TechnicalInterview();
    secondInterviewDetails = new SecondInterview();
    dataChanged = false;
    public notesListId = null;
    user: any;
    public applicantId = null;
    public discussionListId;
    public logListId;
    public resumeFileId;
    public onReloadNotes: Observable<boolean>;
    private _$reloadNotes: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);
    fileType: any;
    isLoading = false;
    filters = [];
    editMode = false
    public queryService = new DiscovererQueryService();
    private routeSnapshot: ActivatedRouteSnapshot;
    private returnUrl: string;
    public context: DataSourceContext;
    public allDataRequestID = '14ea355f-a766-4e8b-9509-1beacd3dfd88';
    constructor(
        @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
        public route: ActivatedRoute,
        public snackBar: MatSnackBar,
        @Optional() public dialogRef: MatDialogRef<ApplicantDetailsComponent>,
        private esearchSettingsService: EsearchSettingsService,
        private fileService: FilesServiceInterface,
        private sanitizer: DomSanitizer,
        dialog: MatDialog,
        private _applicantInterviewService: ApplicantInterviewService,
        private userDataService: UserDataService,
        private _applicantService: ApplicantService,
        public dialogService: DialogsManagerService,
        private matSnackBar: MatSnackBar,
        private _cdRef: ChangeDetectorRef,
        private tabSettings: TabSettingsService,
        private zone: NgZone,
        public titleService: Title,
        public httpDataService: HttpFormsDataService
    ) {
        super(data, dialogRef, dialog,
            esearchSettingsService, httpDataService, dialogService,
            Applicant, 'ats', 'applicants', snackBar, titleService);
        this.routeSnapshot = this.getRouteSnapshot(data, route);
        this.userDataService.loggedInUser.subscribe(user => {
            this.user = user;
        });

        this.onReloadNotes = this._$reloadNotes.asObservable();
    }

    ngOnInit(): void {

        this.zone.runTask(async t => {
            if (!!this.routeSnapshot) {
                this.requestId = 'default';
                this.returnUrl = this.routeSnapshot.queryParams.returnUrl;
                this.start = 0;

                if (this.routeSnapshot.data.appId) {
                    this.appId = this.routeSnapshot.data.appId;
                    this.tabId = this.routeSnapshot.paramMap.get('tabId') ?? this.routeSnapshot.data.tabId;

                    await this.tabSettings.setCurrentTab(this.tabId);
                    var params = Object.assign({}, this.routeSnapshot.queryParams);
                    const tab = (await this.tabSettings.getCurrentTab())
                    params[tab.businessKey] = this.routeSnapshot.paramMap.get('recordId');
                    buildQueryFromParams(this.queryService, params);
                }
            }


        });
    }

    copyTitleWithLink(): void {
        const currentUrl = window.location.href;
        const htmlLink = `<a href="${currentUrl}">${this.rowDetails.name}</a>`;

        // Create a temporary element to hold the HTML
        const tempElement = document.createElement('div');
        tempElement.innerHTML = htmlLink;
        document.body.appendChild(tempElement);

        // Use the Clipboard API to copy the link as HTML
        const range = document.createRange();
        range.selectNode(tempElement);
        window.getSelection()?.removeAllRanges();
        window.getSelection()?.addRange(range);

        // Execute the copy command
        document.execCommand('copy');
        window.getSelection()?.removeAllRanges();

        // Clean up the temporary element
        document.body.removeChild(tempElement);

        console.log('Link copied to clipboard as HTML');
    }
    public async contextChanged(context: DataSourceContext) {
        if (!!context && !!context.data) {
            this.rowDetails = new Applicant(context.data[0]);
            this.iframeResumeUrl = undefined;
            this.applicantId = this.rowDetails.applicantId;
            this.discussionListId = this.rowDetails.discussionListId;
            this.logListId = this.rowDetails.logListId;
            this.context = context;
            await this.loadResumeFileUrl();
            this.loadRelatedInterviews();
            this.historyFilters = [buildFacetFilter('ApplicantId', [this.applicantId])];

            this.titleService.setTitle(`${this?.rowDetails?.name || "Applicant Details"}`)
            this.setContext(context);
        }
    }


    public async onSubmit(context: DataSourceContext) {
        await this.eSearchSettingsService.updateRecordIndexing(this.appId, this.tabId, 'ApplicantId', [this.rowDetails.applicantId.toString()]);
        context.dataService.refresh();
        this.context.pageChange(this.context.query.start, 1);
        this._$reloadNotes.next(true);
    }

    public async updateDiscussionListId(listId) {
        this.discussionListId = listId;
        await this._applicantService.updateDiscussionListId(this.applicantId, listId).toPromise();
        await this.esearchSettingsService.updateRecordIndexing(this.appId, this.tabId,
            'ApplicantId', [this.applicantId.toString()]);
    }

    private async loadRelatedInterviews() {

        this._applicantInterviewService.getByApplicantId(this.applicantId).subscribe((interviews: IApplicantInterviewDto[]) => {


            const technicalInterviews = interviews.filter((z: any) => z.interviewType === 'Technical').map(item => {
                return {
                    id: item.id,
                    applicantId: item.applicantId,
                    interviewDate: item.interviewDate,
                    technicalLevel: item.technicalLevel,
                    examScore: item.examScore,
                    examResult: item.examResult,
                } as ITechnicalInterview;
            });


            if (technicalInterviews.length > 0) {
                this.technicalInterviewDetails = new TechnicalInterview(technicalInterviews[0]);
            } else {
                this.technicalInterviewDetails = new TechnicalInterview();
                this.technicalInterviewDetails.applicantId = this.applicantId;
            }
            this.technicalInterviewDetails.logListId = this.logListId;


            const secondInterviews = interviews.filter((z: any) => z.interviewType === 'Second').map(item => {
                return {
                    id: item.id,
                    applicantId: item.applicantId,
                    interviewDate: item.interviewDate,
                    expectedSalary: item.expectedSalary,
                    notes: item.notes,
                    availableOn: item.availableOn,
                    questions: item.questions,
                } as ISecondInterview;
            });


            if (secondInterviews.length > 0) {
                this.secondInterviewDetails = new SecondInterview(secondInterviews[0] as any);
            } else {
                this.secondInterviewDetails = new SecondInterview();
                this.secondInterviewDetails.applicantId = this.applicantId;
            }
            this.secondInterviewDetails.logListId = this.logListId;

        })

    }

    public close() {
        this.dialogRef.close({ reload: this.dataChanged });
    }

    public generatePDF(eventPDF, landscape?: boolean) {
        if (eventPDF) {
            this.isGeneratePDF = true;
            setTimeout(() => {
                this.exportElement(this.pdfContainer.nativeElement, {
                    paperSize: 'A4',
                    scale: 0.45,
                    landscape,
                    forcePageBreak: '.page-break'
                });
            });
        }
    }

    copyNameWithLink() {
        const currentUrl = window.location.href;
        const text = `${this.rowDetails.name} \n${currentUrl}`;
        navigator.clipboard.writeText(text).catch(err => console.error('Failed to copy text: ', err));
    }

    public onResumeUpload(event) {
        this.loadResumeFileUrl();
    }

    public async onDelete(context: DataSourceContext) {
        const afterClosed = this.dialogService.openConfirmDialog('Delete Applicant', 'Are you sure you want to delete this applicant?');
        afterClosed.subscribe(async x => {
            if (x) {
                this.isLoading = true;
                try {
                    var id = this.rowDetails.applicantId;
                    var response = await this._applicantService.delete(id).toPromise();

                    if (response) {

                        await this.esearchSettingsService.updateRecordIndexing(this.appId, this.tabId, 'ApplicantId', [id.toString()]);

                        setTimeout(async () => {
                            this.details.close();
                            this.dialogRef.close({ refresh: true });
                            this._cdRef.markForCheck();
                        }, 1000);

                        this.matSnackBar.open('Applicant Is Deleted Successfully.', '', {
                            duration: 3000,
                        });

                    }
                    else {
                        this.snackBar.open('Something wont wrong please try later !!!!!', 'Close', { duration: 50000 });
                    }

                } catch (error) {
                    console.error('Error deleting applicant:', error);
                    this.isLoading = false;
                }
            }
        });

    }
    async loadResumeFileUrl(): Promise<void> {
        this.resumeFileUrl = null;
        this.iframeResumeUrl = null;
        if (this.rowDetails.resumeFileId) {
            this.fileService.getFile(this.rowDetails.resumeFileId).pipe(first()).subscribe(x => {
                const blob = new Blob([x], { type: x.type });
                this.resumeFileUrl = window.URL.createObjectURL(blob);
                this.iframeResumeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(window.URL.createObjectURL(blob));
                this.fileType = x.type;
            }, (error) => {
                this.snackBar.open('Somethig wrong happened while loading the resume file.', '', { duration: 1000 });
            });
        }
    }

    private exportElement(element: HTMLElement, options?: DrawOptions) {
        drawDOM(element, options)

            .then((group: Group) => exportPDF(group)).then((dataUri) => {
                saveAs(dataUri, `Applicants Details.pdf`, {
                    forceProxy: true,
                    proxyURL: '/api/fileProxy',
                });
                this.isGeneratePDF = false;
            });
    }

    private getRouteSnapshot(data: any, route: ActivatedRoute) {
        // Dialog vs Standard Route.
        if (!!data) {
            return data.routeSnapshot as ActivatedRouteSnapshot;
        } else if (!!route) {
            return route.snapshot;
        }
    }
}
