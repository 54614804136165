export enum TaskStatus {
    Pending = 0,
    Completed = 1,
    Canceled = 2
}
export interface ITask {
    Id: number;
    CreatedById: number;
    CreatedBy: string;
    CreatedOn: Date;
    Subject: string;
    Description: string;
    AssigneeId: number;
    DueDate: Date;
    CompletedBy: string;
    CompletedOn: Date;
    StatusId: number;
    Status: string;
    Options: string;
    CallbackUrl: string;
    RequestType: string;
    Assignee: string;
    AssigneeEmail: string;
    Response: string;
    EmailResultToRequestorFlag: boolean;
    WFReferenceId: string;
    WFName: string;
    OriginalRequest: string;
    RelatedToId: number;
    RelatedTo: string;
    FormKey: string;
    TargetUrl: string;
    Relation:string;

}
export class Task {
    Id: number;
    RequestType: string;
    CreatedById: number;
    CreatedBy: string;
    Subject: string;
    Options: string;
    Assignee: string;
    AssigneeId: number;
    DueDate: Date;
    AssigneeEmail: string;
    CreatedOn: Date;
    CallbackUrl: string;
    CompletedBy: string;
    CompletedOn: Date;
    Response: string;
    StatusId: number;
    Status: string;
    EmailResultToRequestorFlag: boolean;
    Description: string;
    WFReferenceId: string;
    WFName: string;
    OriginalRequest: string;
    RelatedToId: number;
    RelatedTo: string;
    FormKey: string;
    TargetUrl: string;
    Relation:string;

    constructor(obj: ITask) {
        this.Id = obj.Id;
        this.RequestType = obj.RequestType;
        this.CreatedById = obj.CreatedById;
        this.CreatedBy = obj.CreatedBy;
        this.Subject = obj.Subject;
        this.Options = obj.Options;
        this.Assignee = obj.Assignee;
        this.AssigneeId = obj.AssigneeId;
        this.DueDate = obj.DueDate;
        this.AssigneeEmail = obj.AssigneeEmail;
        this.CreatedOn = obj.CreatedOn;
        this.CallbackUrl = obj.CallbackUrl;
        this.CompletedBy = obj.CompletedBy;
        this.CompletedOn = obj.CompletedOn;
        this.Response = obj.Response;
        this.StatusId = obj.StatusId;
        this.Status = obj.Status;
        this.EmailResultToRequestorFlag = obj.EmailResultToRequestorFlag;
        this.Description = obj.Description;
        this.WFReferenceId = obj.WFReferenceId;
        this.WFName = obj.WFName;
        this.OriginalRequest = obj.OriginalRequest;
        this.RelatedToId = obj.RelatedToId;
        this.RelatedTo = obj.RelatedTo;
        this.FormKey = obj.FormKey;
        this.TargetUrl = obj.TargetUrl;
        this.Relation=obj.Relation;
    }
}
