import { Apollo } from 'apollo-angular';
import { Component, Input, Output, EventEmitter } from '@angular/core';
import { FormsDataServiceInterface } from '@discoverer/dynamic-forms/dyn-forms/services';
import { BaseController } from '@discoverer/core';
import { EmployeeFileFromService } from './employee-file-form.service';


@Component({
    selector: 'app-employee-file-from',
    templateUrl: './employee-file-from.component.html',
    styleUrls: ['./employee-file-from.component.scss'],
    providers: [
        { provide: FormsDataServiceInterface, useClass: EmployeeFileFromService, deps: [Apollo] }
    ]
})
export class EmployeeFilesComponent extends BaseController {
    @Output() isSubmitted = new EventEmitter();
    @Input()
    set data(value) {
        this._data = value;
        if (this._data.AttachmentsIds) {
            this._data.attachmentsIds = value.AttachmentsIds.split(',');
        } else {
            this._data.attachmentsIds = [];
        }
    }
    get data() {
        return this._data;
    }
    @Input()
    public appId = 'ats';
    @Input()
    public set tabId(value) {
        this._tabId = value;
    }
    public get tabId() {
        return this._tabId;
    }
    private _tabId;
    private _data;

    constructor() {
        super();
    }

    public async onSubmit(event) {
        this.isSubmitted.emit(event);
    }

}
