import { Component } from '@angular/core';
import { AuthService } from '@discoverer/app-core';
import { UserData, UserDataService } from '@discoverer/app-core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  public loggedIn = false;
  title = 'ATS';

  constructor(
    private _userDataService: UserDataService,
    private _authService: AuthService,
  ) {
    console.log("Hello World")
    //this._authService.monitorForTokenParamAndFetchUser();
    this._authService.gatewayIdentityUserObservable.subscribe(user => {
     // console.error('user =',user);
      if (!!user) {
        const userData = new UserData();
        userData.name = user.fullName;
        userData.email = user.email;
        userData.id = user.userId;
        userData.token = user.token;
        userData.roles = new Set<string>(user.roles);
        this._userDataService.setUser(userData);
      }
    });
  }
}
