import { switchMap, map, catchError, filter, first, last } from 'rxjs/operators';
import { DynForm, IDynForm } from '../common/models';
import { FormsDataServiceInterface } from '../services';
import { Injectable } from '@angular/core';
import { BlocPatternServiceBase } from '../services/bloc-pattern-service-base';
import { of } from 'rxjs';

@Injectable()
export class GetDynFormBloc extends BlocPatternServiceBase<string, DynForm> {
    private _key: string;
    public setKey(key: string) {
        this._key = key;
        this._$input.next(key);
    }

    constructor(private formsApolloService: FormsDataServiceInterface) {
        super();
        this._$input.pipe(switchMap(key => {
            return this.formsApolloService.getFormByKey(key).pipe(map(result => {
                return new DynForm(result as IDynForm);
            }));
        })).subscribe(s => {
            this._$output.next({ input: this._key, output: s as DynForm });
        });
    }
}

interface IResultType {
    isBusy: boolean;
    result: string;
    error?: any;
}
@Injectable()
export class SubmitDynFormBloc extends BlocPatternServiceBase<any, IResultType> {
    private _data: any;

    public async submit(data): Promise<IResultType> {
        this._data = data;
        this._$input.next(data);
        return this._$output.pipe(filter(p => p.input === data), first(), map(p => p.output)).toPromise();
    }

    constructor(private formsDataService: FormsDataServiceInterface) {
        super();
        this._$input
            .pipe(switchMap(data => {
                return this.formsDataService
                    .postFormData(data)
                    .pipe(
                        map(result => {

                            return { isBusy: false, result };
                        }),
                        catchError(val =>

{

return of({ error: val.error ? val.error : '' });

} )
                    );
            })).subscribe((s: { isBusy: boolean, result: string }) => {
                this._$output.next({ output: s, input: this._data });
            });

        this._$input.subscribe(input => {
            this._$output.next({ output: { isBusy: true, result: null }, input: this._data });
        });
        this._$output.next({ output: { isBusy: false, result: null }, input: this._data });
    }
}
