import { ModuleWithProviders, NgModule } from '@angular/core';
import { ApolloModule, APOLLO_OPTIONS } from 'apollo-angular';

import { take } from 'rxjs/operators';
import { MatSnackBar, MatSnackBarModule } from '@angular/material/snack-bar';
import { AuthService } from '@discoverer/app-core';
import { DefaultOptions, HttpLink, InMemoryCache } from '@apollo/client/core';
import { onError } from "@apollo/client/link/error";
import { setContext } from "@apollo/client/link/context";

export class GraphQLOptions {
    uri: string;
    appKeys: string[];
}

const defaultOptions: DefaultOptions = {
    watchQuery: {
        fetchPolicy: 'no-cache',
        errorPolicy: 'ignore',
    },
    query: {
        fetchPolicy: 'no-cache',
        errorPolicy: 'all',
    },
    mutate: {
        errorPolicy: 'all',
        fetchPolicy: 'no-cache'
    }
};

export function createApollo(userService: AuthService, matSnackBar: MatSnackBar, graphQLOptions: GraphQLOptions) {

    const errorLink = onError(({ graphQLErrors, networkError }) => {
        if (graphQLErrors) {
            graphQLErrors.map(({ message, locations, path }) =>
                matSnackBar.open(
                    `Error : Message: ${message}, Path: ${path}`, 'Close', { duration: 3500 }
                ),
            );
        }
        if (networkError) {
            matSnackBar.open(`[Network error]: ${networkError}`, 'Close', { duration: 3500 });
        }
    });


    const authMiddleware = setContext(async (operation, { headers }) => {

        const tempheaders = {
            'Content-Type': 'application/json',
        };

        const user = await userService.gatewayIdentityUserObservable.pipe(take(1)).toPromise();

        if (user && user.userId) {
            const roles = graphQLOptions.appKeys.map(key => [...user.roles].filter(x => x.split(':')[0] === key)[0]).filter(role => role !== undefined);
            tempheaders['X-Hasura-Default-Role'] = tempheaders['X-Hasura-Default-Role'] || roles[0];
            tempheaders['X-Hasura-User-Id'] = tempheaders['X-Hasura-User-Id'] || user.userId;
            tempheaders['Authorization'] = user.token;
        } else {
            // tslint:disable-next-line:no-string-literal
            tempheaders['Authorization'] = 'anonymous';
        }
        return {
            headers: tempheaders
        };
    });

    return {
        link: authMiddleware.concat(errorLink).concat(new HttpLink({ uri: graphQLOptions.uri })),
        cache: new InMemoryCache(),
        defaultOptions
    };
}
@NgModule({
    exports: [ApolloModule],
    imports: [MatSnackBarModule],
    providers: [{
        provide: APOLLO_OPTIONS,
        useFactory: createApollo,
        deps: [AuthService, MatSnackBar, GraphQLOptions],
    }]
})
export class GraphQLModule {
    static forRoot(uri: string, appKeys: string[]): ModuleWithProviders<GraphQLModule> {
        return {
            ngModule: GraphQLModule,
            providers: [
                { provide: GraphQLOptions, useValue: { uri, appKeys } }
            ]
        };
    }
}
