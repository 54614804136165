import { Component, Output, EventEmitter, Input } from '@angular/core';
import { FormsDataServiceInterface } from '@discoverer/dynamic-forms/dyn-forms/services';
import { ApplicantDetailsFromService } from '../applicant-details-form.service';

@Component({
    selector: 'app-applicant-resume',
    templateUrl: './applicant-resume-form.component.html',
    providers: [
        { provide: FormsDataServiceInterface, useClass: ApplicantDetailsFromService },
    ]
})
export class ApplicantResumeComponent {
    @Output()
    isSubmitted = new EventEmitter<boolean>();

    @Output()
    valueChanged = new EventEmitter<boolean>();
    @Input()
    model: any = {};
    constructor() {

    }
    public onSubmit(event) {
        if (event) {
            this.isSubmitted.emit(true);
        }
    }
    public Changed(event) {
        this.valueChanged.emit(event);
    }
}
