import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { UserDataService } from '@discoverer/app-core';
import { ReportPersistService, TabSettingsService } from '@discoverer/core';
import { NewAssetDialogComponent } from '../new-asset/new-asset-dialog';

@Component({
    selector: 'app-asset-actions',
    templateUrl: `./asset-actions.component.html`,
    styleUrls: [`./asset-actions.component.scss`]
})
export class AssetActionsComponent implements OnInit {

    public currentTab;
    public isAdmin = false;

    public constructor(
        private dialog: MatDialog,
        public reportPersist: ReportPersistService,
        private _tabSettings: TabSettingsService,
        private _activatedRoute: ActivatedRoute,
        private _router: Router,
        private userDataService: UserDataService
    ) {

    }


    async ngOnInit() {
        this.currentTab = await this._tabSettings.getCurrentTab();
        const user = await this.userDataService.getUser();
        // this.isAdmin = user.roles.has(AppSettings.AT_ADMIN_ROLE);
    }
    public async addNewAsset() {
        const dialogRef = this.dialog.open(NewAssetDialogComponent, {
            data: { addNewOnly: true, IsAssignable: true },
            width: '960px',
            panelClass: 'white-class-details-dialog',
            disableClose: true
        });

        dialogRef.afterClosed().subscribe(async x => {
            const id = this._activatedRoute.snapshot.params.id;
            this.reportPersist.mainQueryService.refresh();
            if (x && x.Id) {
                this._router.navigate([`ats/reports/detail/assets/${id}/0`],
                    { queryParams: { Id: x.Id, returnUrl: `ats/reports/assets/${id}` } });
            }
        });
    }

}
