import { Component, Inject, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { OptionListDataServiceInterface, OptionDataServiceInterface } from '@discoverer/dynamic-forms/option-lists/shared-services/data-service';
import { FormsDataServiceInterface } from '@discoverer/dynamic-forms/dyn-forms/services';
import { HasuraOptionService } from '@discoverer/dynamic-forms/option-lists/shared-services/hasura-option-service';
import { HasuraOptionListService } from '@discoverer/dynamic-forms/option-lists/shared-services/hasura-option-list-service';
import { NewApplicantFormService } from '../../new-applicant/new-applicant-form.service';
import { DynFormComponent } from '../../../../../../discoverer/dynamic-forms/dyn-forms/dyn-form/dyn-form.component';

@Component({
    // tslint:disable-next-line:component-selector
    selector: 'new-applicant-dialog',
    templateUrl: './new-applicant-dialog.html',
    styleUrls: ['./new-applicant-dialog.scss'],
    providers: [
        { provide: OptionListDataServiceInterface, useClass: HasuraOptionListService },
        { provide: OptionDataServiceInterface, useClass: HasuraOptionService },
        { provide: FormsDataServiceInterface, useClass: NewApplicantFormService }
    ]
})
export class NewApplicantDialogComponent {

    @ViewChild('dynForm') dynForm: DynFormComponent;
    public isLoading = false;
    constructor(
        public dialogRef: MatDialogRef<NewApplicantDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any) {
        data.isPublic = false;
    }

    close() {
        this.dialogRef.close();
    }

    onSubmit(event) {
        this.isLoading = false;
        if (event) {
            this.dialogRef.close(event);
        }
        else {
            this.isLoading = false;
        }
    }

    public save() {

        this.isLoading = true;

        if (!this.dynForm.form.valid) {
            this.isLoading = false;
        }


        this.dynForm.submit();
    }
}
