<disco-details #details appId="ats" tabId="employees" (onDelete)="deleteRow($event)"  (context)="setContext($event)">
  <ng-template #headerTemplate let-context>
    <div class="header title-header">
      <div>
        <div style="color: var(--dark-600); text-transform: uppercase; font-size: 14px;">Employee</div>
        <h1 class="title-header big-title">{{currentRow.Name}}</h1>
      </div>
    </div>
  </ng-template>
  <ng-template #bodyTemplate let-context>
    <div class="row info-container">
      <mat-card class="card employee-details">
        <div class="inner-area">
          <div class="section basic-info">
            <div class="title-row">
              <h2 class="big-title">Information</h2>
              <app-disco-form-control class="controls" title="EDIT" [form]="detailForm">
              </app-disco-form-control>
            </div>
            <app-disco-form #detailForm appId="ats" tabId="employees" formKey="details" [data]="currentRow"
              (onSubmit)="submitEmployeeForm($event)"></app-disco-form>
          </div>
          <div class="section equipment">
            <div class="title-row">
              <h2 class="big-title">Assets</h2>
              <button  mat-raised-button color="primary" class="add-btn" (click)="addNewEquipment()">+ Asset</button>
            </div>
            <mat-tab-group>
              <mat-tab label="Currently Assigned">
                <assigned-assets #currentAssets [employee]="currentRow" [currentlyAssigned]="true"
                  (dataChanged)="assetsHistory.refresh()"></assigned-assets>
              </mat-tab>
              <mat-tab label="History">
                <assigned-assets #assetsHistory [employee]="currentRow" [currentlyAssigned]="false"
                  (dataChanged)="currentAssets.refresh()"></assigned-assets>
              </mat-tab>
            </mat-tab-group>
          </div>
          <div class="section access">
            <div class="title-row">
              <h2 class="big-title">Access</h2>
              <button   mat-raised-button color="primary" style="margin-left: 10px" class="add-btn"
                (click)="addNewAccess()">+ Access</button>
            </div>
            <mat-tab-group>
              <mat-tab label="Currently Assigned">
                <accessible-apps #currentAccess [employee]="currentRow" [currentlyAssigned]="true"
                  (dataChanged)="accessHistory.refresh()"></accessible-apps>
              </mat-tab>
              <mat-tab label="History">
                <accessible-apps #accessHistory [employee]="currentRow" [currentlyAssigned]="false"
                  (dataChanged)="currentAccess.refresh()"></accessible-apps>
              </mat-tab>
            </mat-tab-group>
          </div>
          <div class="section tasks">
            <div class="title-row">
              <h2 class="big-title">Requests</h2>
              <button  mat-raised-button color="primary" class="add-btn" (click)="addNewRequest()">+ Request</button>
            </div>

            <mat-tab-group>
              <mat-tab label="Pending">
                <related-tasks [employee]="currentRow" [isCompleted]="false" [isLoading]="isLoading"></related-tasks>
              </mat-tab>
              <mat-tab label="Completed">
                <related-tasks [employee]="currentRow" [isCompleted]="true" [isLoading]="isLoading"></related-tasks>
              </mat-tab>
            </mat-tab-group>
          </div>
        </div>
      </mat-card>
      <mat-card class="files-card">
        <app-employee-file-from [appId]="appId" [tabId]="tabId" [data]="currentRow" (isSubmitted)="onSubmit($event);">
        </app-employee-file-from>
      </mat-card>
    </div>
  </ng-template>
</disco-details>