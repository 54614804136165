<div class="title">
    <span>Employee Access</span>
    <mat-icon class="close-button" (click)="close()">close</mat-icon>
</div>
<app-dyn-form #dynForm class="form" [key]="'details'" [model]="data" [showTitle]="false" [isEditMode]="true"
    (result)="onSubmit($event)">
</app-dyn-form>
<div class="buttons">
    <button mat-raised-button class="save-button" color="primary" mat-button (click)="dynForm.submit()">SAVE</button>
    <button mat-raised-button mat-button (click)="close()">Cancel</button>
</div>