export enum UserRoleEnum {
    Admin = "ats:admin",
    User = "ats:user"
}


export enum ErrorCode {
    Success = "200",
    NotFound = "404",
    Unauthorized = "401",
    BadRequest = "400",
    InternalServerError = "500",
    Duplicate = "409"
}
