<disco-data (context)="contextChanged($event)" [appId]="'ats'" [tabId]="'assignments'" [requestId]="defaultRequestId"
    [filters]="filters" [start]="1" [size]="20" [sorts]="sorts">
    <ng-template let-context #myTemplate>
        <div *ngIf="context" id="standard-table">
            <div class="main-div">
                <kendo-grid [data]="filteredData" [pageSize]="20"
                    (pageChange)="context.pageChange($event.skip, $event.take)" [sort]="sort"
                    (sortChange)="sortChange($event)" [sortable]="{allowUnsort: true}" [navigable]="true"
                    [resizable]="true" [reorderable]="true">
                    <div *ngFor="let column of columns;">
                        <kendo-grid-column [field]="column.fieldName" [format]="column.format" [title]="column.display"
                            [width]="column.width">
                            <ng-template *ngIf="column.fieldName=='SerialNumber'" kendoGridCellTemplate let-dataItem
                                let-rowIndex="rowIndex">
                                <span class="hyperlink" (click)="editAssignment(dataItem)">
                                    {{dataItem[column.fieldName]}}</span>
                            </ng-template>
                            <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                                <span> {{ fs.getValue(dataItem[column.fieldName] , column.dataType)}}</span>
                            </ng-template>
                        </kendo-grid-column>
                    </div>
                </kendo-grid>
            </div>
        </div>
    </ng-template>
</disco-data>