import { Component, EventEmitter, Output, ViewChild } from '@angular/core';
import { DynFormComponent } from '@discoverer/dynamic-forms/dyn-forms/dyn-form/dyn-form.component';
import { FormsDataServiceInterface, HttpFormsDataService } from '@discoverer/dynamic-forms/dyn-forms/services';
import { AssetService } from '../../services/asset.service';

@Component({
    // tslint:disable-next-line:component-selector
    selector: 'asset-lookup',
    template: `<app-disco-form [autoSave]=false formKey="asset-lookup"
                [isEditMode]="true" (valueChanged)="onValueChange($event)">
                </app-disco-form>`,
    providers: [
        { provide: FormsDataServiceInterface, useClass: AssetService },
    ]
})
export class AssetLookupComponent {
    @ViewChild('dynForm') dynForm: DynFormComponent;
    @Output() valueSelected = new EventEmitter();

    constructor(defaultForm: FormsDataServiceInterface) {
        (defaultForm as HttpFormsDataService).formServicePath = `/api/es/form/${this.appId}/${this.tabId}`;
    }

    public appId = 'ats';
    public tabId = 'assets';

    onValueChange(value) {
        this.valueSelected.emit(value);
    }
}
