<div class="controls">
    <button mat-raised-button *ngIf="!isEditMode" color="accent" mat-button (click)="isEditMode = true">EDIT</button>

    <!-- <mat-spinner *ngIf="isLoading" [diameter]="30" color="primary"></mat-spinner>

    <button mat-raised-button *ngIf="isEditMode" color="primary" mat-button [disabled]="isLoading"
        (click)="save()">SAVE</button> -->

        <mat-spinner *ngIf="isLoading" [diameter]="30" color="primary"></mat-spinner>
        <button mat-raised-button *ngIf="isEditMode" color="primary" mat-button [disabled]="isLoading"
            (click)="save()">SAVE</button>




    <button mat-flat-button *ngIf="isEditMode" color="secondary" mat-button (click)="isEditMode = false">CANCEL</button>
</div>
<div class="interview applicant-form-wrapper">
    <app-dyn-form #dynForm class="form" [key]="formKey" [model]="model" [showTitle]="false" [isEditMode]="isEditMode"
        (result)="onSubmit($event)">
    </app-dyn-form>
</div>
