import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { NewApplicantDialogComponent } from '../applicant-dialogs/new-applicant-dialog/new-applicant-dialog';
import { Applicant } from '@app/ATS/models/applicant';
import { HasuraOptionService } from '@discoverer/dynamic-forms/option-lists/shared-services/hasura-option-service';
import { EsearchSettingsService, ReportPersistService, TabSettingsService } from '@discoverer/core';
import { ImportApplicantDialogComponent } from '../applicant-dialogs/import-applicant-dialog/import-applicant-dialog';
import { NewApplicantImportLinkDialogComponent } from '../applicant-dialogs/new-applicant-import-link-dialog/new-applicant-import-link-dialog';

@Component({
    selector: 'app-applicant-actions',
    templateUrl: `./applicants-actions.component.html`,
    styleUrls: [`./applicants-actions.component.scss`],
    providers: [HasuraOptionService]
})
export class ApplicantActionsComponent implements OnInit {

    public constructor(
        private dialog: MatDialog,
        private esearchSettingsService: EsearchSettingsService,
        public reportPersist: ReportPersistService,
        private _tabSettings: TabSettingsService,
        private optionService: HasuraOptionService
    ) {

    }
    public currentTab;
    public statusList;
    public newOptionId;
    public data: Applicant;
    async ngOnInit() {
        this.currentTab = await this._tabSettings.getCurrentTab();
        this.statusList = ['New', 'Screen call', 'Interviewed', 'On hold', 'Shortlisted', 'Trial'];
    }
    public async addNewApplicant() {


        this.newOptionId = await this.optionService.getOptionIdByName('New');
        this.data = new Applicant();
        this.data.statusId = this.newOptionId;
        const dialogRef = this.dialog.open(NewApplicantDialogComponent, {
            data: this.data,
            width: '100vw',
            height: '100vh',
            maxWidth: '1000px',
            panelClass: 'white-class-details-dialog',
        });

        dialogRef.afterClosed().subscribe(async x => {
            setTimeout(() => {
                this.reportPersist.mainQueryService.refresh();
            }, 500);
        });
    }
    public async importApplicant() {

        this.newOptionId = await this.optionService.getOptionIdByName('New');
        this.data = new Applicant();
        this.data.statusId = this.newOptionId;
        const dialogRef = this.dialog.open(ImportApplicantDialogComponent, {
            data: this.data,
            width: '100vw',
            height: '100vh',
            maxWidth: '1000px',
            panelClass: 'white-class-details-dialog',
        });

        dialogRef.afterClosed().subscribe(async x => {

                setTimeout(() => {
                    this.reportPersist.mainQueryService.refresh();
                }, 500);
        });
    }
    public async newApplicantImportLink() {
    const dialogRef = this.dialog.open(NewApplicantImportLinkDialogComponent, {
        data: this.data,
        width: '100vw',
        height: '100vh',
        maxWidth: '1000px',
        panelClass: 'white-class-details-dialog',
    });

    dialogRef.afterClosed().subscribe(async x => {

        setTimeout(() => {
            this.reportPersist.mainQueryService.refresh();
        }, 500);
    });

}

}
