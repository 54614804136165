import { Injectable } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';
import { FormsDataServiceInterface } from '../../../../../discoverer/dynamic-forms/dyn-forms/services/forms-data-service.interface';
import { getApplicantDetailsForms } from './applicant-details.froms';
import { ApplicantService } from '../../services/apis/applicant.service';
import { IApplicantDto } from '../../../../models/dtos/applicant.dto';



@Injectable()
export class ApplicantDetailsFromService implements FormsDataServiceInterface {
    constructor(private _applicantService: ApplicantService) {
    }

    public getFormByKey(key: string) {
        const form = getApplicantDetailsForms().filter(f => f.Key === key)[0];
        const obj = {
            Key: key,
            Title: form.Title,
            Configuration: JSON.stringify(form.Configuration)
        };
        return of(obj).delay(1);
    }


    postFormData(object: IApplicantDto): Observable<IApplicantDto> {
        return this._applicantService.update(object.applicantId, object);

    }
}


