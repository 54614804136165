import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { IAssetFileDto } from '../../../../models/dtos/asset-file.dto';
import { ApiCallerService } from './api-caller.service';

const BASE_URL = `/api/asset-files`;

@Injectable()
export class AssetFileService {

    constructor(private _apiCallerService: ApiCallerService) { }


    public create(dtos: IAssetFileDto[]): Observable<IAssetFileDto[]> {
        return this._apiCallerService.post<IAssetFileDto[]>(`${BASE_URL}`, dtos );
    }
}
