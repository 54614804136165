<disco-data (context)="contextChanged($event)" [appId]="appId" [tabId]="'assignments'" [requestId]="defaultRequestId"
    [filters]="filters" [start]="1" [size]="20" [sorts]="sorts">
    <ng-template let-context #myTemplate>
        <div *ngIf="context" id="standard-table">
            <div class="main-div">
                <kendo-grid [data]="gridView" [pageSize]="20"
                    (pageChange)="context.pageChange($event.skip, $event.take)" [sort]="sort"
                    (sortChange)="sortChange($event)"  [sortable]="{allowUnsort: true}" [navigable]="true"
                    [resizable]="true" [reorderable]="true">
                    <div *ngFor="let column of columns;">
                        <kendo-grid-column [field]="column.fieldName" [format]="column.format" [title]="column.display"
                            [width]="column.width">
                            <ng-template kendoGridCellTemplate let-dataItem="dataItem" let-columnIndex="columnIndex">
                                <span *ngIf="column.dataType =='icon';then iconField else field"></span>
                                <ng-template #field>
                                    <span> {{  formattingService.getValue(dataItem[column.fieldName] , column.dataType)}}</span>
                                </ng-template>
                                <ng-template #iconField>
                                    <span class="material-icons"> {{dataItem[column.fieldName] }}</span>
                                </ng-template>
                            </ng-template>
                        </kendo-grid-column>
                    </div>
                </kendo-grid>
            </div>
        </div>
    </ng-template>
</disco-data>
