import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { HttpFormsDataService } from '@discoverer/dynamic-forms/dyn-forms/services';
import { DialogsManagerService } from '@discoverer/dynamic-reports/dialogs';
import { Task, TaskStatus } from '@app/workflow/common/models/task';
import { DataFormattingService } from '@discoverer/core';
import { environment } from '@env/environment';
import { orderBy } from '@progress/kendo-data-query';
import { Subject } from 'rxjs';
import { CommonRelatedEntityController } from '../../../common/common-related-entity.controller';
import { TaskDialogComponent } from './task-dialog/task-dialog';

@Component({
    // tslint:disable-next-line:component-selector
    selector: 'related-tasks',
    templateUrl: './related-tasks.component.html',
    styleUrls: ['./related-tasks.component.scss'],
    providers: [
        HttpFormsDataService
    ]
})

export class RelatedTasksComponent extends CommonRelatedEntityController<Task>  {
    @Output() dataChanged = new EventEmitter(false);
    @Input() resetReportSubject: Subject<boolean> = new Subject<boolean>();
    @Input() isCompleted: boolean;
    @Input() isLoading = false;

    @Input() set employee(value) {
        this._employee = value;
        this.refreshFilters(value);
    }

    public formattingService = new DataFormattingService();
    public defaultRequestId = 'afbd0032-9993-44a0-0000-e1111c083333';
    private _employee;

    constructor(
        public snackBar: MatSnackBar,
        public dialog: MatDialog,
        private httpDatService: HttpFormsDataService,
        public dialogService: DialogsManagerService
    ) {
        super(Task, dialogService, dialog, 'ats', 'tasks', TaskDialogComponent, ['Subject', 'Assignee',
            'CreatedBy', 'CreatedOn', 'DueDate', 'Description'], 'CreatedOn', 'desc');
        this.httpDatService.formServicePath = `/api/es/form/ats/tasks`;
    }

    public navigateToTask(dataItem) {
        window.open(`${environment.baseURL}/ats/reports/detail/tasks/default/0?Id=${dataItem.Id}&returnUrl=ats/reports/tasks`, '_blank');
    }

    public get filteredData() {
        const data = this.context.data;
        if (this.context) {
            if (this.isCompleted) {
                return {
                    data: orderBy(data.filter(d => d.StatusId === TaskStatus.Completed), this.sort),
                    total: data.length
                };
            }

            return {
                data: orderBy(data.filter(d => d.StatusId !== TaskStatus.Completed), this.sort),
                total: data.length
            };

        }
        return this.gridView;
    }

    private refreshFilters(value: any) {
        this.filters = [{ type: 'facet', filters: [], fields: ['RelatedToId'], expression: [value.Id] }];
    }
}
