export interface ISecondInterview {
    id: number;
    applicantId: number;
    interviewDate?: Date;
    expectedSalary?: number;
    notes?: string;
    availableOn: string;
    questions: string;
}
export class SecondInterview {
    applicantId: number;
    interviewDate?: Date;
    expectedSalary?: number;
    notes?: string;
    availableOn?: string;
    id: number;
    interviewType = 'Second';
    questions: string | any;
    logListId?: number;
    constructor(obj?: ISecondInterview) {
        this.applicantId = obj && obj.applicantId || null;
        this.interviewDate = obj && obj.interviewDate || null;
        this.expectedSalary = obj && obj.expectedSalary || null;
        this.notes = obj && obj.notes || '';
        this.availableOn = obj && obj.availableOn || null;
        this.id = obj && obj.id || 0;
        this.questions = obj && obj.questions ? JSON.parse(obj.questions) : '';
    }
}

