<div class="dialog-container">
    <div class="note">
        <span [innerHTML]="data.note">
        </span>
    </div>
    <div class="buttons-row">
        <button *ngIf="!forConfirmation" mat-button class="round-button" (click)="close(true)">
            OK
        </button>
        <button *ngIf="forConfirmation" mat-button class="round-button" (click)="close(true)">
            YES
        </button>
        <button *ngIf="forConfirmation" mat-button class="round-button" (click)="close(false)">
            NO
        </button>
    </div>
</div>