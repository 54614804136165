import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { IDiscussionNoteDto } from '../../../../models/dtos/discussion-note.dto';
import { ApiCallerService } from './api-caller.service';
import { IDiscussionNoteListDto } from '../../../../models/dtos/discussion-note-list.dto';
const BASE_URL = `/api/ignt-discussions`;


@Injectable()
export class DiscussionNotesService {
    constructor(private _apiCallerService: ApiCallerService) {
    }
    public createNoteList(): Observable<IDiscussionNoteListDto> {
        return this._apiCallerService.post<IDiscussionNoteListDto>(`${BASE_URL}/note-lists`,null);
    }

}
