export interface IUser {
    userId: number;
    email: string;
    fullName: string;
    roles: string[];
}

export abstract class UserServiceInterface {

    constructor() {
    }

    public abstract getUser(): Promise<IUser>;
}
