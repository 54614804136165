import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ITask } from '../models/task';
import { ITaskResponse } from '../models/task-response';

@Injectable()
export class RequestsService {

    constructor(private _httpClient: HttpClient) { }

    public respondToRequest(appId: string, requestId: number, response): Promise<ITask> {
        return this._httpClient.post<ITask>(
            `api/es/user-task/appId/${appId}/request/${requestId}/${response}`, {}).toPromise();
    }

    public loadWFRelatedTasks(appId: string, wfRefId: string): Promise<ITaskResponse[]> {
        return this._httpClient.get<ITaskResponse[]>(
            `api/es/user-task/${appId}/related-tasks/wfRefId/${wfRefId}`).toPromise();
    }

}
