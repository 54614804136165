import { Component, OnInit } from '@angular/core';
import { ReportPersistService, TabSettingsService } from '@discoverer/core';

@Component({
    selector: 'app-applicant-filters',
    templateUrl: `./applicants-filters.component.html`,
    styleUrls: [`./applicants-filters.component.scss`]
})
export class ApplicantFiltersComponent implements OnInit {

    public constructor(
        public reportPersist: ReportPersistService,
        private _tabSettings: TabSettingsService,
    ) {

    }
    public currentTab;
    public statusList;
    async ngOnInit() {
        this.currentTab = await this._tabSettings.getCurrentTab();
        this.statusList = ['New', 'Screen call', 'Interview', 'On hold', 'Shortlist', 'Trial'];

    }


}
