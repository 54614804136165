import { ComponentType } from '@angular/cdk/portal';
import { Optional, Directive } from '@angular/core';
import { Inject } from '@angular/core';
import { ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Title } from '@angular/platform-browser';
import { CustomConfirmDialog, DialogsManagerService } from '@discoverer/dynamic-reports/dialogs';
import { HttpFormsDataService } from '@discoverer/dynamic-forms/dyn-forms/services';
import { take } from 'rxjs/operators';
import { MatSnackBar } from '@angular/material/snack-bar';
import { BaseController, DataSourceContext, EsearchSettingsService } from '@discoverer/core';
import { UserData } from '@discoverer/app-core';
import { DiscoDetailsComponent, DiscoFormComponent } from '@discoverer/dynamic-reports';


export interface IDetailRow {
  Id: string | number;
  Name?: string;
  IsDeleted?: boolean;
}

export type IDetailRowConstructor<T extends IDetailRow> = new (data: any) => T;

@Directive()
// tslint:disable-next-line:directive-class-suffix
export class CommonDetailsController<T extends IDetailRow> extends BaseController {
  currentRow: T;
  selectedIndex = 0;
  refreshId: string | number;
  user: UserData;
  protected context: DataSourceContext;

  @ViewChild('details') details: DiscoDetailsComponent;
  @ViewChild('detailForm') detailForm: DiscoFormComponent;

  constructor(
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
    @Optional() public dialogRef: MatDialogRef<any>,
    protected dialog: MatDialog,
    protected eSearchSettingsService: EsearchSettingsService,
    protected httpDataService: HttpFormsDataService,
    protected dialogService: DialogsManagerService,
    protected detailType: IDetailRowConstructor<T>,
    public appId: string,
    public tabId: string,
    public snackBar: MatSnackBar,
    public title: Title,
  ) {
    super();
  }

  public async onSubmit(event) {
    if (event) {
      const response = await this.eSearchSettingsService.updateRecordIndexing(this.appId, this.tabId, 'Id', [this.currentRow.Id.toString()]);
      this.refreshForm();
      this.details.hasDataChanged = true;
    }
  }

  setContext(event: DataSourceContext) {

    if (event && event.data) {
      if (!event.data.length) {
        this.details.close();
      }
      if (this.refreshId && (event.data[0].Id !== this.refreshId)) {
        this.snackBar.open('The record you updated  is not in the filtered list anymore', 'X', {
          duration: 3000
        });
      }
      if (this.currentRow && (this.currentRow.Id !== event.data[0].Id)) {
        this.selectedIndex = 0;
      }
      this.currentRow = Object.assign({}, new this.detailType(event.data[0]));
      if (!!this.currentRow.Name) {
        this.title.setTitle(`${this.tabId.charAt(0).toUpperCase() + this.tabId.slice(1)} - ${this.currentRow.Name}`);
      }
      this.context = event;
      this.refreshId = null;
    }
  }

  // tslint:disable-next-line:no-shadowed-variable
  public async openRelatedDialog<T>(componentType: ComponentType<T>, data: any) {
    const dialogRef = this.dialog.open(componentType, {
      data,
      width: '1400px',
      maxWidth: '94vw',
      panelClass: 'white-class-details-dialog',
      disableClose: true
    });

    const result = await dialogRef.afterClosed().toPromise();
    this.refreshForm();
  }

  public async openConfirmDialog(title: string, message: string) {
    const dialogRef = this.dialog.open(CustomConfirmDialog, {
      data: { title, message },
      width: '550px',
      panelClass: 'white-class-details-dialog',
    });

    return await dialogRef.afterClosed().toPromise();
  }

  public async deleteRow(event) {
    const confirmed = await this.openConfirmDialog('Delete', 'Are you sure you want to delete this row?');
    if (confirmed === true) {
      const dataResource = new this.detailType(event.data[0]);
      dataResource.IsDeleted = true;
      this.httpDataService.formServicePath = `/api/es/form/${this.appId}/${this.tabId}`;
      const response = await this.httpDataService.postFormData(dataResource).toPromise();
      if (response) {
        setTimeout(() => {
          this.details.hasDataChanged = true;
          this.details.close();
        }, 300);
      }
    }
  }

  public async refreshForm() {
    this.context.pageChange(this.context.query.start, 1);
    const x = await this.context.dataService.oData.pipe(take(1)).toPromise();
    if (x && x.length && x[0]) {
      this.refreshId = new this.detailType(x[0]).Id;
    }
  }
  

}
