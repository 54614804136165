import { Component, ViewEncapsulation, Optional, ViewChild } from '@angular/core';
import { Applicant } from '@app/ATS/models/applicant';
import { MatDialogRef } from '@angular/material/dialog';
import { OptionListDataServiceInterface, OptionDataServiceInterface } from '@discoverer/dynamic-forms/option-lists/shared-services/data-service';
import { FormsDataServiceInterface } from '@discoverer/dynamic-forms/dyn-forms/services';
import { HasuraOptionService } from '@discoverer/dynamic-forms/option-lists/shared-services/hasura-option-service';
import { HasuraOptionListService } from '@discoverer/dynamic-forms/option-lists/shared-services/hasura-option-list-service';
import { FilesServiceInterface } from '@discoverer/dynamic-forms/dyn-forms/dyn-upload/files-service.interface';
import { UserDataService, UserDataServiceInterface } from '@discoverer/app-core';
import { FilesService, FILES_SERVICE_URL } from '@discoverer/dynamic-reports/disco-details/files-service';
import { EsearchSettingsService } from '@discoverer/core';
import { NewApplicantFormService } from './new-applicant-form.service';

@Component({
    selector: 'new-applicant',
    templateUrl: './new-applicant.component.html',
    styleUrls: ['./new-applicant.component.scss'],
    encapsulation: ViewEncapsulation.None,
    providers: [
        { provide: OptionListDataServiceInterface, useClass: HasuraOptionListService },
        { provide: OptionDataServiceInterface, useClass: HasuraOptionService },
        { provide: FormsDataServiceInterface, useClass: NewApplicantFormService },
        { provide: UserDataServiceInterface, useExisting: UserDataService },
        { provide: FilesServiceInterface, useClass: FilesService },
        { provide: FILES_SERVICE_URL, useValue: '/api/es/public/attachments/ats/dir' },
        EsearchSettingsService
    ]
})

export class NewApplicantComponent {
    @ViewChild('dynForm', { static: false }) dynForm;
    constructor(
        private userDataService: UserDataService,
        @Optional() public dialogRef: MatDialogRef<NewApplicantComponent>) {
    }
    data: any = {};
    dataChanged = false;
    public appId = 'ats';
    public tabId = 'applicants';
    public details = new Applicant();
    public id: any;
    public hasApplied = false;
    public isLoading = false;

    public ngOnInit() {
        this.data.isPublic = true;
        this.data.applicationDate = new Date();


        this.userDataService.loggedInUser.subscribe(x => {
            if (x && x.id) {
                this.data.isPublic = false;
                this.data.applicationDate = null;
            }
        });
    }


    public onSubmit(event) {
        if (event) {
            this.hasApplied = true;
        }
    }
    public save() {


        this.isLoading = true;

        if (!this.dynForm.form.valid) {
            this.isLoading = false;
        }

        this.dynForm.submit();
    }

    public close() {
        this.dialogRef.close({ reload: this.dataChanged });
    }
}
