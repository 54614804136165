import { Component, Output, EventEmitter, Input } from '@angular/core';
import { FormsDataServiceInterface } from '@discoverer/dynamic-forms/dyn-forms/services';
import { UserDataService } from '@discoverer/app-core';
import { IDiscussionNoteDto } from '@app/ATS/models/dtos/discussion-note.dto';
import { ApplicantDetailsFromService } from '../applicant-details-form.service';
import { IApplicantDto } from '@app/ATS/models/dtos/applicant.dto';
import { ApplicantService } from '../../../services/apis/applicant.service';

@Component({
    selector: 'app-applicant-rating-form',
    templateUrl: './applicant-rating-form.component.html',
    providers: [
        { provide: FormsDataServiceInterface, useClass: ApplicantDetailsFromService },
    ]
})
export class ApplicantRatingComponent {
    @Output()
    isSubmitted = new EventEmitter<boolean>();

    @Input()
    public model: any = {};
    public user: any;
    public isLoading = false;
    constructor(
        private _userService: UserDataService,
        private _applicantService: ApplicantService) {
        this._userService.loggedInUser.subscribe((userData) => { this.user = userData; });
    }
    public onChange() {
        this.isLoading = true;
    }
    public async onSubmit(applicant: IApplicantDto) {
        if (applicant) {

            this.isLoading = true;
            let listId = this.model.logListId;
            const rating = applicant.rating ?? '';

            var notes: IDiscussionNoteDto[] = [{
                body: 'Changed Rating To ' + rating,
                createdOn: new Date().toString(),
                notesListId: listId,
                createdById: +this.user.id
            }];

            this._applicantService.createApplicantNotesForLog(applicant.id, listId, notes).subscribe(x => {
                this.isSubmitted.emit(true);
                this.isLoading = false;
            });
        }
        else
            this.isLoading = false;
    }
}
