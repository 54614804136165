import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { UserDataService } from '@discoverer/app-core';
import { ReportPersistService, TabSettingsService } from '@discoverer/core';
import { NewTaskDialogComponent } from '../new-task/new-task-dialog';

@Component({
    selector: 'app-task-actions',
    templateUrl: `./task-actions.component.html`,
    styleUrls: [`./task-actions.component.scss`]
})
export class TaskActionsComponent implements OnInit {

    public constructor(
        private dialog: MatDialog,
        public reportPersist: ReportPersistService,
        private _tabSettings: TabSettingsService,
        private userDataService: UserDataService
    ) {

    }
    public currentTab;
    public data = {} as any;

    async ngOnInit() {
        this.currentTab = await this._tabSettings.getCurrentTab();
        const user = await this.userDataService.getUser();
        this.data.CreatedById = +user.id;
    }

    public async addNewTask() {
        const dialogRef = this.dialog.open(NewTaskDialogComponent, {
            data: this.data,
            width: '800px',
            panelClass: 'white-class-details-dialog'
        });

        dialogRef.afterClosed().subscribe(async x => {
            setTimeout(() => {
                this.reportPersist.mainQueryService.refresh();
            }, 1500);
        });
    }

}
