import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'main-nav',
    templateUrl: `./main-nav.component.html`,
    styleUrls: [`./main-nav.component.scss`],
    providers: [
    ]
})
export class MainNavComponent implements OnInit {

    ngOnInit() {
    }

}
