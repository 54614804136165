import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { HttpFormsDataService } from '../common/services/workflow-forms-data-service';
import { DynTaskComponent } from '../start/dyn-task/dyn-task.component';

@Component({
    selector: 'app-task-completion',
    templateUrl: './task-completion.component.html',
    styleUrls: ['./task-completion.component.scss'],
    providers: [HttpFormsDataService]
})

export class TaskCompletionComponent {
    @ViewChild('dynTask') dynTask: DynTaskComponent;
    @Input() key: string;
    @Input() data;
    @Input() callbackUrl;

    @Output() isSubmitted = new EventEmitter();


    constructor(private httpClient: HttpClient) { }

    public async submitTaskFormResponse() {
        const data = {

            body: JSON.stringify(this.data),
            response: 'Complete'
        };
        const res = await this.httpClient.post(this.callbackUrl, data).toPromise();
        this.isSubmitted.emit(res);
    }
}
