import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NotesComponent } from './components/notes/notes.component';
import { DragDropDirective } from './directives/drag-drop.directive';
import { ConfirmDeletionDialogComponent } from './components/notes/confirm-deletion-dialog/confirm-deletion-dialog';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatBadgeModule } from '@angular/material/badge';
import { MatMenuModule } from '@angular/material/menu';
import { NotesConfig, NOTES_SERVICE_CONFIG } from './services/notes.service';
import { NotificationsCenterComponent } from './components/notification-center/notifications-center.component';
import { SortPipe } from './custom-pipes/sort.pipe';

@NgModule({
    declarations: [
        NotesComponent,
        DragDropDirective,
        ConfirmDeletionDialogComponent,
        NotificationsCenterComponent,
        SortPipe
    ],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        MatInputModule,
        MatButtonModule,
        MatIconModule,
        MatTooltipModule,
        MatProgressSpinnerModule,
        MatSelectModule,
        MatButtonToggleModule,
        MatBadgeModule,
        MatInputModule,
        MatMenuModule
    ],
    exports: [
        NotesComponent,
        DragDropDirective,
        NotificationsCenterComponent
    ],
    entryComponents: [
        ConfirmDeletionDialogComponent
    ],
    providers: [SortPipe]
})
export class NotesModule {
    static forRoot(notesConfig: NotesConfig): ModuleWithProviders<NotesModule> {
        return {
            ngModule: NotesModule,
            providers: [
                { provide: NOTES_SERVICE_CONFIG, useValue: notesConfig }
            ]
        };
    }
}
