
<div class="title">
    <span>New Public Import Link</span>
    <mat-icon class="close-button" (click)="close()">close</mat-icon>
</div>
<div class="mt-10 mr-10" style="height: calc(90% - 64px); overflow: auto;">
    <app-dyn-form #dynForm class="form" [key]="'new-applicant-import-link'" [model]="model" [showTitle]="false" [isEditMode]="true"
        (result)="onSubmit($event)" >
    </app-dyn-form>
</div>
<div class="buttons">
    <button mat-raised-button class="save-button" color="primary" mat-button (click)="dynForm.submit()">SAVE</button>
    <button mat-raised-button mat-button (click)="close()">Cancel</button>
</div>