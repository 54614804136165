<div class="dialog-container">
    <div class="message">
        <span>
            {{data.message}}
        </span>
    </div>
    <div class="buttons-row">
        <button mat-button class="round-button" (click)="close(true)">
            YES
        </button>
        <button mat-button class="round-button" (click)="close(false)">
            NO
        </button>
    </div>
</div>