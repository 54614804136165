import { HttpClient } from '@angular/common/http';
import { Component, Inject, OnInit, Optional, ViewEncapsulation } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Title } from '@angular/platform-browser';
import { DialogsManagerService } from '@discoverer/dynamic-reports/dialogs';
import { DISCO_FORM_SERVICE_URL, HttpFormsDataService } from '@discoverer/dynamic-forms/dyn-forms/services';
import { CommonDetailsController } from '../../common/common-details-controller';
import { NewAssetDialogComponent } from '../../assets/new-asset/new-asset-dialog';
import { NewTaskDialogComponent } from '../../tasks/new-task/new-task-dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Employee } from '@app/ATS/models/employee';
import { NewAccessDialogComponent } from '../../new-access/new-access-dialog';
import { UserDataService } from '@discoverer/app-core';
import { EsearchSettingsService } from '@discoverer/core';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'app-employee-details',
  templateUrl: './employee-details.component.html',
  styleUrls: ['../../reports.component.scss', './employee-details.component.scss'],
  providers: [
    { provide: HttpFormsDataService, useClass: HttpFormsDataService, deps: [HttpClient] },
    { provide: DISCO_FORM_SERVICE_URL, useValue: `api/es/form/ats/employees` }

  ],
  encapsulation: ViewEncapsulation.None,
})
export class EmployeeDetailsComponent extends CommonDetailsController<Employee> implements OnInit {
  formServicePath = `/api/es/form/${this.appId}/employees`;
  isLoading = false;
  isHR = false;
  isAdmin = false;

  constructor(
    private userDataService: UserDataService,
    @Optional() @Inject(MAT_DIALOG_DATA) data: any,
    @Optional() dialogRef: MatDialogRef<EmployeeDetailsComponent>,
    dialogService: DialogsManagerService,
    eSearchSettingsService: EsearchSettingsService,
    defaultForm: HttpFormsDataService,
    dialog: MatDialog,
    snackBar: MatSnackBar,
    titleService: Title
  ) {
    super(data, dialogRef, dialog,
      eSearchSettingsService, defaultForm, dialogService,
      Employee, 'ats', 'employees', snackBar, titleService);
  }

  async ngOnInit() {
    const user = await this.userDataService.getUser();
    // this.isHR = user.roles.has(AppSettings.AT_HR_ROLE);
    // this.isAdmin = user.roles.has(AppSettings.AT_ADMIN_ROLE);
  }

  public async submitEmployeeForm(event) {
    if (event) {
      const response = await this.eSearchSettingsService.updateRecordIndexing(this.appId, this.tabId, 'Name', [this.currentRow['Name'].toString()]);
      this.refreshForm();
      this.details.hasDataChanged = true;
    }
  }

  public async addNewEquipment() {
    await this.openRelatedDialog(NewAssetDialogComponent,
      {
        CurrentAssignedEmployeeId: this.currentRow.Id,
        SiteId: this.currentRow.SiteId,
        AssignedSince: new Date(),
        addNewOnly: false
      }
    );
  }

  public async addNewAccess() {
    await this.openRelatedDialog(NewAccessDialogComponent,
      {
        EmployeeId: this.currentRow.Id,
        SiteId: this.currentRow.SiteId,
        StartDate: new Date(),
        preFilled: true
      }
    );
  }

  public async addNewRequest() {
    const userId = +(await this.userDataService.getUser()).id;

    const data = {
      RelatedToId: this.currentRow.Id,
      SiteId: this.currentRow.SiteId,
      CreatedById: userId,
      preFilled: true
    };

    const dialogRef = this.dialog.open(NewTaskDialogComponent, {
      data,
      width: '800px',
      maxWidth: '94vw',
      panelClass: 'white-class-details-dialog',
      disableClose: true
    });

    const result = await dialogRef.afterClosed().toPromise();
    if (result) {
      this.isLoading = true;
      setTimeout(() => {
        this.refreshForm();
        this.isLoading = false;
      }, 5000);
    }
  }
}
