import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { HttpFormsDataService } from '@discoverer/dynamic-forms/dyn-forms/services';
import { DialogsManagerService } from '@discoverer/dynamic-reports/dialogs';
import { Subject } from 'rxjs';
import { NewAssetDialogComponent } from '../../new-asset/new-asset-dialog';
import { CommonRelatedEntityController } from '../../../common/common-related-entity.controller';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Asset } from '@app/ATS/models/asset';
import { DataFormattingService } from '@discoverer/core';

@Component({
    // tslint:disable-next-line:component-selector
    selector: 'asset-history',
    templateUrl: './asset-history.component.html',
    styleUrls: ['./asset-history.component.scss'],
    providers: [
        HttpFormsDataService
    ]
})
export class AssetHistoryComponent extends CommonRelatedEntityController<Asset>  {
    @Output() dataChanged = new EventEmitter(false);
    @Input() resetReportSubject: Subject<boolean> = new Subject<boolean>();
    @Input() set asset(value) {
        this._asset = value;
        this.refreshFilters(value);
    }

    public formattingService = new DataFormattingService();
    public defaultRequestId = 'afbd0032-9993-44a0-a2d0-e5067c083333';
    private _asset;

    constructor(
        public snackBar: MatSnackBar,
        public dialog: MatDialog,
        private httpDatService: HttpFormsDataService,
        public dialogService: DialogsManagerService
    ) {
        super(Asset, dialogService, dialog, 'ats', 'assignments', NewAssetDialogComponent, ['EmployeeName', 'AssignedBy', 'StartDate', 'EndDate'], 'StartDate', 'desc');
        this.httpDatService.formServicePath = `/api/es/form/ats/assignments`;
    }

    private refreshFilters(value: any) {
        let expressionKey = '';
        expressionKey = 'AssetId';
        this.filters = [{ type: 'facet', filters: [], fields: [expressionKey], expression: [value.Id] }];
    }
}
