import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ApplicantActionsComponent } from './applicants/applicant-actions/applicants-actions.component';
import { DiscoListViewComponent } from '@discoverer/dynamic-reports/disco-list-view/disco-list-view.component';
import { AssetDetailsComponent } from './assets/asset-details/asset-details.component';
import { EmployeeDetailsComponent } from './employees/employee-details/employee-details.component';
import { TaskDetailsComponent } from './tasks/task-details/task-details.component';
import { DialogResolverService, DialogRouteConfig, DialogRouterModule } from '@discoverer/core';
import { ApplicantDetailsComponent } from './applicants/applicant-details/applicant-details.component';

const dlgSettings = {
  data: {},
  position: { top: '0', bottom: '0' },
  width: '1240px',
  maxWidth: 'unset',
  panelClass: 'panel-class-details-dialog',
  disableClose: false
} as DialogRouteConfig;

const defaultReportRedirects: Routes = [
  { path: '', redirectTo: 'applicants', pathMatch: 'full' },
  { path: 'applicants', redirectTo: 'applicants/default', pathMatch: 'full' },
  { path: 'employees', redirectTo: 'employees/default', pathMatch: 'full' },
  { path: 'assets', redirectTo: 'assets/default', pathMatch: 'full' },
  { path: 'tasks', redirectTo: 'tasks/default', pathMatch: 'full' },
];


const routes: Routes = [
  {
    path: 'detail',
    children: [
      {
        path: 'applicants/:recordId',
        component: ApplicantDetailsComponent,
        resolve: { dlgRef: DialogResolverService },
        data: {
          appId: 'ats',
          tabId: 'applicants',
          dlg: dlgSettings,
        }
      },
      {
        path: 'employees/:recordId',
        component: EmployeeDetailsComponent,
        resolve: { dlgRef: DialogResolverService },
        data: {
          appId: 'ats',
          tabId: 'employees',
          dlg: dlgSettings,
        }
      },
      {
        path: 'assets/:recordId',
        component: AssetDetailsComponent,
        resolve: { dlgRef: DialogResolverService },
        data: {
          appId: 'ats',
          tabId: 'assets',
          dlg: dlgSettings,
        }
      },
      {
        path: 'tasks/:recordId',
        component: TaskDetailsComponent,
        resolve: { dlgRef: DialogResolverService },
        data: {
          appId: 'ats',
          tabId: 'tasks',
          dlg: dlgSettings,
        }
      }
    ]
  },
  {
    path: 'reports',
      children: [
        ...defaultReportRedirects,
        {
          path: 'applicants/:id',
          component: DiscoListViewComponent,
          data: {
            appId: 'ats',
            tabId: 'applicants'
          } ,children: [
            {
              path: '',
              component: ApplicantActionsComponent,
              outlet: 'actions'
            }
          ]
        },
        {
          path: 'tasks/:id',
          component: DiscoListViewComponent,
          data: {
            appId: 'ats',
            tabId: 'tasks'
          }
        },
        {
          path: 'employees/:id',
          component: DiscoListViewComponent,
          data: {
            appId: 'ats',
            tabId: 'employees'
          }
        },
        {
          path: 'assets/:id',
          component: DiscoListViewComponent,
          data: {
            appId: 'ats',
            tabId: 'assets',
          }
        },
      ]
  }
  
];

@NgModule({
  declarations: [],
  imports: [
    RouterModule.forChild(routes),
    DialogRouterModule,
  ],
  exports: [
    RouterModule,
    DialogRouterModule
  ],
  providers: []
})
export class ReportsRoutingModule {
}
