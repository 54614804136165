import { Component, Inject, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { OptionListDataServiceInterface, OptionDataServiceInterface } from '../../../../../../discoverer/dynamic-forms/option-lists/shared-services/data-service';
import { FormsDataServiceInterface } from '../../../../../../discoverer/dynamic-forms/dyn-forms/services/forms-data-service.interface';
import { HasuraOptionListService } from '../../../../../../discoverer/dynamic-forms/option-lists/shared-services/hasura-option-list-service';
import { HasuraOptionService } from '../../../../../../discoverer/dynamic-forms/option-lists/shared-services/hasura-option-service';
import { DynFormComponent } from '@discoverer/dynamic-forms/dyn-forms/dyn-form/dyn-form.component';
import { ImportApplicantFormService } from '../../import-applicant/import-applicant-form.service';

@Component({
    // tslint:disable-next-line:component-selector
    selector: 'import-applicant-dialog',
    templateUrl: './import-applicant-dialog.html',
    styleUrls: ['./import-applicant-dialog.scss'],
    providers: [
        { provide: OptionListDataServiceInterface, useClass: HasuraOptionListService },
        { provide: OptionDataServiceInterface, useClass: HasuraOptionService },
        { provide: FormsDataServiceInterface, useClass: ImportApplicantFormService }

    ]
})
export class ImportApplicantDialogComponent {
    @ViewChild('dynForm') dynForm: DynFormComponent;
    public isLoading = false;
    constructor(
        public dialogRef: MatDialogRef<ImportApplicantDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any) {

        data.isPublicApplication = false;
        this.data.applicationDate = new Date();
        data.isImportApplicant = true;


    }
    close() {
        this.dialogRef.close();
    }

    onSubmit(event) {
        this.isLoading = false;
        if (event) {
            this.dialogRef.close(event);
        }
        else {
            this.isLoading = false;
        }
    }

    public save() {

        this.isLoading = true;

        if (!this.dynForm.form.valid) {
            this.isLoading = false;
        }


        this.dynForm.submit();
    }


}
