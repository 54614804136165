import { NgModule } from '@angular/core';
import { ExtraOptions, PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { LandingComponent, LayoutComponent } from '@discoverer/app-core';
import { AppGuardService } from './app.guard.service';
import { InitialDataResolver } from './app.resolvers';
import { NewApplicantComponent } from './ATS/components/reports/applicants/new-applicant/new-applicant.component';
import { ImportApplicantComponent } from './ATS/components/reports/applicants/import-applicant/import-applicant.component';


const routes: Routes = [
    { path: 'auth/landing', component: LandingComponent },
    {
        path: '',
        canActivate: [AppGuardService],
        component: LayoutComponent,
        data: {
            layout: 'modern'
        },
        resolve: {
            initialData: InitialDataResolver,
        },
        children: [
            { path: 'ats', loadChildren: () => import('./ATS/ATS.module').then(m => m.ATSModule), canActivate: [AppGuardService] },
            { path: 'ats', outlet: 'dialog', loadChildren: () => import('./ATS/ATS.module').then(m => m.ATSModule), canActivate: [AppGuardService] }
        ]
    },
    { path: 'ats/new-applicant', component: NewApplicantComponent },
    { path: 'ats/import-applicant/:jobAppliedForId', component: ImportApplicantComponent },


];

const routerConfig: ExtraOptions = {
    preloadingStrategy: PreloadAllModules,
    scrollPositionRestoration: 'enabled',
    useHash: false,
    relativeLinkResolution: 'legacy'
};

@NgModule({
    imports: [RouterModule.forRoot(routes, routerConfig)],
    exports: [RouterModule],
    providers: [AppGuardService]
})
export class AppRoutingModule {
}
