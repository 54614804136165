import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { EmployeeAssignment } from '@app/ATS/models/employee-assignment';
import { HttpFormsDataService } from '@discoverer/dynamic-forms/dyn-forms/services';
import { DialogsManagerService } from '@discoverer/dynamic-reports/dialogs';
import { DataFormattingService } from '@discoverer/core';
import { orderBy } from '@progress/kendo-data-query';
import { Subject } from 'rxjs';
import { CommonRelatedEntityController } from '../../../common/common-related-entity.controller';
import { AssignmentDialogComponent } from './assignment-dialog/assignment-dialog';

@Component({
    // tslint:disable-next-line:component-selector
    selector: 'assigned-assets',
    templateUrl: './assigned-assets.component.html',
    styleUrls: ['./assigned-assets.component.scss'],
    providers: [
        HttpFormsDataService
    ]
})
export class AssignedAssetsComponent extends CommonRelatedEntityController<EmployeeAssignment>  {
    @Output() dataChanged = new EventEmitter(false);
    @Input() resetReportSubject: Subject<boolean> = new Subject<boolean>();
    @Input() currentlyAssigned: boolean;
    @Input() set employee(value) {
        this._employee = value;
        this.refreshFilters(value);
    }

    public formattingService = new DataFormattingService();
    public defaultRequestId = 'afbd0032-9993-44a0-a2d0-e5067c083333';
    private _employee;

    constructor(
        public snackBar: MatSnackBar,
        public dialog: MatDialog,
        private httpDatService: HttpFormsDataService,
        public dialogService: DialogsManagerService
    ) {
        super(EmployeeAssignment, dialogService, dialog, 'ats', 'assignments', AssignmentDialogComponent, ['Type', 'Subtype', 'SerialNumber',
             'Name', 'Description', 'StartDate', 'EndDate'], 'Type', 'asc');
        this.httpDatService.formServicePath = `/api/es/form/ats/assets`;
    }

    public async editAssignment(dataItem) {
        await this.editDataItem(dataItem);
        this.dataChanged.emit(true);
    }

    public get filteredData() {
        if (this.context) {
            return {
                data: orderBy(this.context.data.filter(d => d.IsCurrentlyAssigned === this.currentlyAssigned), this.sort),
                total: this.context.data.length
            };
        }
        return this.gridView;
    }

    private refreshFilters(value: any) {
        this.filters = [
            { type: 'facet', filters: [], fields: ['EmployeeId'], expression: [value.Id] },
        ];
    }
}
