import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { ReportPersistService, TabSettingsService } from '@discoverer/core';
import { NewEmployeeDialogComponent } from '../new-employee/new-employee-dialog';

@Component({
    selector: 'app-employee-actions',
    templateUrl: `./employee-actions.component.html`,
    styleUrls: [`./employee-actions.component.scss`]
})
export class EmployeeActionsComponent implements OnInit {

    public constructor(
        private dialog: MatDialog,
        public reportPersist: ReportPersistService,
        private _tabSettings: TabSettingsService,
        private _activatedRoute: ActivatedRoute,
        private _router: Router
    ) {

    }
    public currentTab;
    public data = { Type: 'Employee' };
    async ngOnInit() {
        this.currentTab = await this._tabSettings.getCurrentTab();
    }
    public async addNewEmployee() {
        const dialogRef = this.dialog.open(NewEmployeeDialogComponent, {
            data: this.data,
            width: '960px',
            height: '535px',
            panelClass: 'white-class-details-dialog',
            disableClose: true
        });

        dialogRef.afterClosed().subscribe(async x => {
            const id = this._activatedRoute.snapshot.params.id;
            this.reportPersist.mainQueryService.refresh();
            if (x && x.Id) {
                this._router.navigate([`ats/reports/detail/employees/${id}/0`],
                    { queryParams: { Id: x.Id, returnUrl: `ats/reports/employees/${id}` } });
            }
        });
    }

}
