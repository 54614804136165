import { JsonPipe } from '@angular/common';

export interface IBaseModel {
    id: number;
    description: string;
}

export interface IApplicant {
    ApplicantId: number;
    PhoneNumber: string;
    Name: string;
    Email: string;
    StatusId: number;
    Status: string;
    JoinDate: Date;
    UniversityId: number;
    University: string;
    OtherUniversity: string;
    OtherSource: string;
    MajorId: number;
    Major: string;
    DegreeId: number;
    Degree: string;
    SchoolGPA: number;
    UniversityGPA: number;
    RatingId: number;
    Rating: string;
    ExperienceInYears: number;
    ExpectedSalary: number;
    CareerLevelId: number;
    CareerLevel: string;
    CurrentPosition: string;
    InterviewDate: Date;
    ExamScore: number;
    SourceId: number;
    Source: string;
    Notes: string;
    Technologies: string;
    ApplicationDate: Date;
    NationalityId: number;
    Nationality: string;
    TagsIds: string[] | string;
    Tags: string[];
    ResumeFileId: number;
    AttachmentsIds: string;
    DiscussionListId: number;
    LogListId: number;
    JobAppliedForId: number,
    JobAppliedFor: string;
    LinkedInURL: string;
    RecommendationBy: string;
}
export class Applicant {
    Id: number;
    applicantId: number;
    phoneNumber: string;
    name: string;
    email: string;
    statusId: number;
    status: string;
    joinDate: Date;
    universityId: number;
    university: string;
    otherUniversity: string;
    otherSource: string;
    majorId: number;
    major: string;
    degreeId: number;
    degree: string;
    schoolGPA: number;
    universityGPA: number;
    ratingId: number;
    rating: string;
    experienceInYears: number;
    expectedSalary: number;
    careerLevelId: number;
    careerLevel: string;
    currentPosition: string;
    interviewDate: Date;
    examScore: number;
    sourceId: number;
    source: string;
    notes: string;
    technologies: string;
    applicationDate: Date;
    nationalityId: number;
    nationality: string;
    tagsIds: number[];
    tags: string[];
    resumeFileId: number;
    attachmentsIds: Array<number>;
    discussionListId: number;
    logListId: number;
    jobAppliedForId: number;
    jobAppliedFor: string;
    isEditMode: boolean;
    LinkedInURL: string;
    RecommendationBy: string;
    constructor(obj?: IApplicant) {
        this.Id = obj && obj.ApplicantId || 0;
        this.applicantId = obj && obj.ApplicantId || 0;
        this.phoneNumber = obj && obj.PhoneNumber || null;
        this.name = obj && obj.Name || null;
        this.email = obj && obj.Email || null;
        this.statusId = obj && obj.StatusId || null;
        this.status = obj && obj.Status || null;
        this.joinDate = obj && obj.JoinDate || null;
        this.universityId = obj && obj.UniversityId || null;
        this.university = obj && obj.University || null;
        this.otherUniversity = obj && obj.OtherUniversity || null;
        this.otherSource = obj && obj.OtherSource || null;
        this.majorId = obj && obj.MajorId || null;
        this.major = obj && obj.Major || null;
        this.degreeId = obj && obj.DegreeId || null;
        this.degree = obj && obj.Degree || null;
        this.schoolGPA = obj && obj.SchoolGPA || null;
        this.universityGPA = obj && this.convertFromGPAtoPerc(obj.UniversityGPA) || null;
        this.ratingId = obj && obj.RatingId || null;
        this.rating = obj && obj.Rating || null;
        this.experienceInYears = obj && obj.ExperienceInYears || null;
        this.expectedSalary = obj && obj.ExpectedSalary || null;
        this.careerLevelId = obj && obj.CareerLevelId || null;
        this.careerLevel = obj && obj.CareerLevel || null;
        this.currentPosition = obj && obj.CurrentPosition || null;
        this.interviewDate = obj && obj.InterviewDate || null;
        this.examScore = obj && obj.ExamScore || null;
        this.sourceId = obj && obj.SourceId || null;
        this.source = obj && obj.Source || null;
        this.notes = obj && obj.Notes || null;
        //this.technologies = !!obj.Technologies ? (obj.Technologies as any as []).join(",") : null;
        this.technologies = obj&& obj.Technologies || null;

        this.applicationDate = obj && obj.ApplicationDate || null;
        this.nationalityId = obj && obj.NationalityId || null;
        this.nationality = obj && obj.Nationality || null;
        this.tagsIds = obj && obj.TagsIds ? Array.isArray(obj.TagsIds) ? obj.TagsIds.map(x => +x) : obj.TagsIds.split(',').map(id => +id) : null;
        this.tags = obj && obj.Tags && obj.Tags || null;
        this.resumeFileId = obj && obj.ResumeFileId || null;
        this.attachmentsIds = obj && obj.AttachmentsIds && obj.AttachmentsIds.split(',').map(id => +id) || null;
        this.discussionListId = obj && obj.DiscussionListId || null;
        this.logListId = obj && obj.LogListId || null;
        this.jobAppliedForId = obj && obj.JobAppliedForId || null;
        this.jobAppliedFor = obj && obj.JobAppliedFor || null;
        this.LinkedInURL = obj && obj.LinkedInURL || null;
        this.RecommendationBy = obj && obj.RecommendationBy || null;
    }

    convertFromGPAtoPerc(GPA){
        const factor = 10 ** 4;
        if(+GPA >= 1 && +GPA <= 4){
            return (Math.round((+GPA*100 /4) * factor) / factor).toString();

        }
        return GPA;
    }
}

