import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { INotesSubscriber } from '../../models/conversation-interfaces';
import { NotificationsServiceInterface } from '../../services/notifications-service-interface';
import { UserServiceInterface } from '../../services/user-service-interface';

@Component({
    selector: 'app-notifications',
    templateUrl: './notifications-center.component.html',
    styleUrls: ['./notifications-center.component.scss']
})

export class NotificationsCenterComponent implements OnInit {
    public notifications = [];
    public userId: number;

    constructor(
        private notificationsService: NotificationsServiceInterface,
        private userService: UserServiceInterface,
        private router: Router) { }

    public async ngOnInit() {
        const user = await this.userService.getUser();
        this.userId = user.userId;
        const subscriptions = await this.getSubscriptions(this.userId);
        this.notifications.push(
            ...
            subscriptions.filter(d => d.notesList.notes.length).map(d => {
                {
                    const note = d.notesList.notes[0];
                    const notification = note.notification;
                    return {
                        body: notification.body,
                        createdOn: note.createdOn,
                        isSeen: d.isSeen,
                        isPrivate: d.notesList.isPrivate,
                        badgeContent: notification.badgeContent,
                        url: notification.url,
                        listId: d.listId
                    };
                }
            }));
    }

    public async markAllAsSeen() {
        this.notifications.forEach(n => n.isSeen = true);
        await this.notificationsService.markAllAsSeen(this.userId);
    }

    public async onNotificationClick(notification) {
        await this.markAsSeen(notification);
        this.router.navigateByUrl(notification.url);
    }

    public get unseenNotificationsCount() {
        return this.notifications && this.notifications.length && this.notifications.filter(n => !n.isSeen).length || 0;
    }

    private async markAsSeen(notification) {
        await this.notificationsService.markAsSeen(notification.listId, this.userId);
        notification.isSeen = true;
    }

    private async getSubscriptions(userId: number): Promise<INotesSubscriber[]> {
        const conversations: INotesSubscriber[] = [];
        const privateConversations = await this.notificationsService.getSubscriptions(userId, false);
        const publicConversations = await this.notificationsService.getSubscriptions(userId, true);
        conversations.push(...privateConversations);
        conversations.push(...publicConversations);
        return conversations;
    }
}
