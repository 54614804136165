import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FormsDataServiceInterface } from '@discoverer/dynamic-forms/dyn-forms/services';
import { OptionDataServiceInterface } from '@discoverer/dynamic-forms/option-lists/shared-services/data-service';
import { HasuraOptionService } from '@discoverer/dynamic-forms/option-lists/shared-services/hasura-option-service';
import { HttpFormsDataService } from '../common/services/workflow-forms-data-service';

@Component({
  selector: 'app-start',
  templateUrl: './start.component.html',
  styleUrls: ['./start.component.scss'],
  providers: [
    { provide: FormsDataServiceInterface, useClass: HttpFormsDataService },
    { provide: OptionDataServiceInterface, useClass: HasuraOptionService }
  ]
})
export class StartComponent {
  @ViewChild('dynList') dynList;

  @Input() public title;
  @Input() public data;
  @Input() public directory = '/public';

  @Input()
  public showInGroups = false;

  @Input()
  public rolePrefix;

  @Output()
  isSubmitted = new EventEmitter<any>();

  @Output()
  isCanceled = new EventEmitter<any>();

  constructor(
    route: ActivatedRoute,
  ) {
    const routeSnapshot = route.snapshot;
    if (!!routeSnapshot) {
      const dir = routeSnapshot.paramMap.get('directory');
      if (dir) {
        this.directory = dir;
      }
    }
  }
}
