export interface IEmployeeAssignment {
    Id: number;
    EmployeeId: number;
    AssetId: number;
    StartDate: Date;
    EndDate?: Date;
    SerialNumber?: string;
    Description?: string;
    CurrentAssignedEmployeeId?: number;
    SiteId?: number;
    TypeId?: number;
    IsAssignable: boolean;
}
 // tslint:disable
export class EmployeeAssignment {
    Id: number;
    EmployeeId?: number;
    AssetId?: number;
    StartDate?: Date;
    EndDate?: Date;
    SerialNumber?: string;
    Description?: string;
    CurrentAssignedEmployeeId?: number;
    SiteId?: number;
    TypeId?: number;
    IsAssignable: boolean;


    constructor(obj: IEmployeeAssignment) {
        this.Id = obj.Id;
        this.EmployeeId = obj.EmployeeId;
        this.AssetId = obj.AssetId;
        this.StartDate = obj.StartDate;
        this.EndDate = obj.EndDate;
        this.SerialNumber = obj.SerialNumber;
        this.Description = obj.Description;
        this.CurrentAssignedEmployeeId = obj.CurrentAssignedEmployeeId;
        this.SiteId = obj.SiteId;
        this.TypeId = obj.TypeId;
        this.IsAssignable = obj.IsAssignable;
    }
}
