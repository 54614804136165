export interface IEmployee {
    Id: number;
    Name: string;
    Title: string;
    PhoneNumber: string;
    Email: string;
    ManagerId: number;
    Manager: string;
    SiteId: number;
    DepartmentId: number;
    Station: string;
    StartDate: Date;
    TermDate: Date;
    ITARRestricted: boolean;
    AttachmentsIds: string;
}
 // tslint:disable
export class Employee {
    Id: number;
    Name: string;
    Title: string;
    PhoneNumber: string;
    Email: string;
    ManagerId: number;
    Manager: string;
    SiteId: number;
    DepartmentId: number;
    Station: string;
    StartDate: Date;
    TermDate: Date;
    ITARRestricted: boolean;
    AttachmentsIds: string;

    constructor(obj: IEmployee) {
        this.Id = obj.Id;
        this.Name = obj.Name;
        this.Email = obj.Email;
        this.Title = obj.Title;
        this.PhoneNumber = obj.PhoneNumber;
        this.ManagerId = obj.ManagerId;
        this.Manager = obj.Manager;
        this.SiteId = obj.SiteId;
        this.DepartmentId = obj.DepartmentId;
        this.Station = obj.Station;
        this.StartDate = obj.StartDate;
        this.TermDate = obj.TermDate;
        this.ITARRestricted = obj.ITARRestricted;
        this.AttachmentsIds = obj.AttachmentsIds;
    }
}
