<div class="main-div">
    <div class="flex items-center mx-2 lg:mr-8">
        <div class="hidden lg:flex">
            <img class="dark:hidden w-32" src="assets/images/logo/capella-logo.png">
            <img class="hidden dark:flex w-32" src="assets/images/logo/capella-logo.png">
        </div>
        <img class="flex lg:hidden w-8" src="assets/images/logo/capella-logo.png">
        <h1 style="padding:4px 4px 4px 8px; color:rgb(19,140,197); font-weight:bold;font-size: 24px;">
            ATS
        </h1>
    </div>
</div>