<ng-container *ngIf="notes?.length > 0 || (!showNoDataSection  && notes?.length == 0); else noDataTemplate">
    <div #scrollDown class="upper-section" *ngIf="notes?.length && currentUser"
        [ngClass]="{'faded': disabled, 'no-title': !showTitle, 'long': !extendedView, 'type': noteType}"
       [ngClass]="{ 'fixed-height': currentUser && !viewOnlyMode}">
        <h2 *ngIf="showTitle">{{title}}</h2>

        <div *ngIf="notes" class="notes-wrapper" [ngClass]="{'no-margin': !showTitle}"  [ngClass]="{ 'fixed-height': currentUser && !viewOnlyMode}">
            <div class="note-container"
                [ngClass]="{'my-note': isMyNote(note.createdBy?.id), 'other-user-note': !isMyNote(note.createdBy?.id)}"
                *ngFor="let note of notes; let i = index; let first = first; let last = last;">
                <div class="note" [ngClass]="{'user-note': note.createdBy?.id == currentUser.id}">
                    <!-- note header -->
                    <ng-container *ngIf="first || (notes[i - 1].createdOn | date:'d') !== (note.createdOn | date:'d')">
                        <div class="flex items-center justify-center my-3 -mx-6">
                            <div class="flex-auto border-b"></div>
                            <div  class="flex-0 mx-4 text-sm font-medium leading-5 text-secondary">
                                {{note.createdOn | date: 'longDate'}}
                            </div>
                            <div class="flex-auto border-b"></div>
                        </div>
                    </ng-container>

                    <div class="flex flex-col" [ngClass]="{'items-end': isMyNote(note.createdBy?.id) ,
                                            'items-start': !isMyNote(note.createdBy?.id)  }">
                        <!-- note Body -->
                        <!-- Bubble -->
                        <div class="relative w-full px-3 py-3 rounded-lg" [ngClass]="{'bg-blue-500 ': isMyNote(note.createdBy?.id),
                                                'bg-gray-500 ': !isMyNote(note.createdBy?.id)}">
                            <!-- Speech bubble tail -->

                            <div class="absolute bottom-0 w-3"
                                [ngClass]="{'text-blue-500 -right-1 -mr-px mb-px': isMyNote(note.createdBy?.id),
                                                        'text-gray-500 -left-1 -ml-px mb-px -scale-x-1': !isMyNote(note.createdBy?.id)}">
                                <ng-container *ngTemplateOutlet="speechBubbleExtension"></ng-container>
                            </div>


                            <span [ngClass]="{' text-blue-50': isMyNote(note.createdBy?.id),
                                                ' text-gray-50': !isMyNote(note.createdBy?.id)}">{{note.body}}</span>

                            <div *ngIf="note.attachments?.length > 0"
                                class="attachments flex-row ng-star-inserted p-2 rounded-lg " [ngClass]="{' bg-blue-300  ': !isMyNote(note.createdBy?.id),
                                                        ' bg-gray-300 ': isMyNote(note.createdBy?.id)}">
                                <div *ngFor="let att of note.attachments" class="attachment row">
                                    <img class="file-icon" *ngIf="att?.fileName" [src]="getLogo(att.fileName)"
                                        onerror="this.src='assets/icons/blank.svg';">
                                    <span class="file-name" (click)="downloadFile(att)"
                                        *ngIf="att?.fileName">{{att.fileName}}</span>
                                    <button class="delete-file-button rounded-lg "
                                        (click)="deleteOldAttachment(note, att.id)" [disabled]="disabled"
                                        [matTooltip]="isMyNote(note.createdBy?.id)?'':'You are not authorized to delete this attachment.'"
                                        [ngClass]="{'disabled': disabled || !isMyNote(note.createdBy?.id)}">
                                        <i class="material-icons material-icons ml-2 mt-1 ">
                                            close
                                        </i>
                                    </button>
                                </div>
                            </div>

                        </div>

                        <!-- Note footer -->
                        <div class="flex flex-row w-full   items-center "
                            [ngClass]="{'justify-end -right-1 -mr-px mb-px': isMyNote(note.createdBy?.id),
                                                        ' items-start -left-1 -ml-px mb-px -scale-x-1': !isMyNote(note.createdBy?.id)}">
                            <div *ngIf="first
                                       || last
                                       || notes[i + 1].createdOn !== note.createdOn"
                                class=" flex my-0.5 text-sm font-medium text-secondary mx-1">

                                <ng-container *ngIf="!isMyNote(note.createdBy?.id)">
                                    {{note.createdBy?.name}}</ng-container>

                                {{note.createdOn | date:'HH:mm'}}


                            </div>
                            <span class=" flex material-icons note-type">
                                {{note.type | lowercase}}
                            </span>

                            <mat-spinner *ngIf="noteIdToDelete && noteIdToDelete.id === note.id"
                                diameter="30"></mat-spinner>

                            <button *ngIf="!noteIdToDelete && !viewOnlyMode" class="flex delete-button " mat-icon-button
                                (click)="deleteNote(note)" [disabled]="disabled"
                                [matTooltip]="isMyNote(note.createdBy?.id)?'':'You are not authorized to delete this note.'"
                                [ngClass]="{'disabled': !isMyNote(note.createdBy?.id)}">
                                <span class="material-icons">
                                    delete_forever
                                </span>
                            </button>


                        </div>

                    </div>



                </div>
            </div>
        </div>
    </div>
</ng-container>

<ng-template #noDataTemplate>
    <div class="flex flex-auto ml-2 mt-50 flex-col items-center justify-center">
        <mat-icon class="icon-size-24" [svgIcon]="'iconsmind:file_hide'"></mat-icon>
        <div class="mt-4 text-2xl font-semibold tracking-tight text-secondary">There is no data to show</div>
    </div>
</ng-template>


<form *ngIf="currentUser && !viewOnlyMode" class="note-form" [formGroup]="notesForm" [ngClass]="{'faded': disabled}">
    <div class="column">
        <div class="title-bar">
            <span class="title">{{subTitle}}</span>
            <div>
                <mat-button-toggle-group *ngIf="noteType" #group [value]="noteType" (change)="noteType = $event.value">
                    <mat-button-toggle value="Note"><span class="material-icons">
                            note
                        </span></mat-button-toggle>
                    <mat-button-toggle value="Call"><span class="material-icons">
                            call
                        </span></mat-button-toggle>
                    <mat-button-toggle value="Email">
                        <span class="material-icons">
                            email
                        </span>
                    </mat-button-toggle>
                </mat-button-toggle-group>
            </div>
            <mat-icon *ngIf="!extendedView">
                <span class="material-icons" (click)="extendedView=true">
                    keyboard
                </span>
            </mat-icon>
            <mat-icon *ngIf="extendedView">
                <span class="material-icons" (click)="extendedView=false">
                    keyboard_hide
                </span></mat-icon>
        </div>
        <div class="flex flex-col w-full pt-2 pb-2 border-t bg-gray-50 dark:bg-transparent">
        </div>

        <div *ngIf="extendedView" class="column box ">
            <div class="row" class="row drop-area" appDragDrop (onFilesDrop)="onFilesSelect($event)"
                (onFilesDragOver)="onFilesDragOver()" (onFilesDragLeave)="onFilesDragLeave()">
                <textarea *ngIf="!fileIsDragged || disabled" formControlName="body" rows="4" autocomplete="off"
                    placeholder="Type text or drop files here">
                        </textarea>
                <span *ngIf="fileIsDragged && !disabled">Drop files here</span>
            </div>
            <div *ngIf="selectedFiles?.length" class="files-list">
                <div class="file" *ngFor="let file of selectedFiles, let i = index">
                    <div class="left-section">
                        <img class="file-icon" *ngIf="file?.name" [src]="getLogo(file.name)"
                            onerror="this.src='assets/icons/blank.svg';">
                        <span> {{ file?.name }} </span>
                    </div>
                    <button class="delete-file-button" (click)="deleteAttachment(i)">
                        <i class="material-icons">
                            close
                        </i>
                    </button>
                </div>
            </div>
        </div>
        <div *ngIf="extendedView" class="row  bottom-bar ">
            <div class="right-side">
                <button [disabled]="disabled" class="bottom-bar-button" (click)="fileInput.click()">
                    <i class="material-icons">
                        attachment
                    </i>
                    <input formControlName="attachment" hidden type="file" #fileInput multiple
                        (change)="onFilesSelect($any($event).target.files, $event)">
                </button>
                <button mat-button *ngIf="(selectedFiles?.length || notesForm.get('body').value) && !addNoteLoading"
                    class="button" (click)="cancel()">
                    Cancel
                </button>
                <mat-spinner *ngIf="addNoteLoading" diameter="30"></mat-spinner>
                <button *ngIf="!addNoteLoading" mat-button
                    [disabled]="disabled || !(notesForm.get('attachment').value || notesForm.get('body').value ) "
                    class="button" (click)="save()">
                    <mat-icon class="transform rotate-90" [svgIcon]="'heroicons_outline:paper-airplane'"></mat-icon>
                </button>
            </div>
        </div>

    </div>
</form>


<ng-template #speechBubbleExtension>
    <svg width="100%" height="100%" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
        <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <path
                d="M1.01522827,0.516204834 C-8.83532715,54.3062744 61.7609863,70.5215302 64.8009949,64.3061218 C68.8074951,54.8859711 30.1663208,52.9997559 37.5036011,0.516204834 L1.01522827,0.516204834 Z"
                fill="currentColor" fill-rule="nonzero"></path>
        </g>
    </svg>
</ng-template>