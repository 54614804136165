import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { IApplicantInterviewDto } from '../../../../models/dtos/applicant-interview.dto';
import { ApiCallerService } from './api-caller.service';

const BASE_URL = `/api/applicant-interviews`;

@Injectable()
export class ApplicantInterviewService {
    constructor(private _apiCallerService: ApiCallerService) { }

    public create(dto: IApplicantInterviewDto): Observable<IApplicantInterviewDto> {
        return this._apiCallerService.post<IApplicantInterviewDto>(BASE_URL, dto);
    }

    public update(id: number, dto: IApplicantInterviewDto): Observable<IApplicantInterviewDto> {
        return this._apiCallerService.put<IApplicantInterviewDto>(`${BASE_URL}/${id}`, dto);
    }

    public getByApplicantId(applicantId: number): Observable<IApplicantInterviewDto[]> {
        return this._apiCallerService.get<IApplicantInterviewDto[]>(`${BASE_URL}/applicants/${applicantId}`);
    }

}