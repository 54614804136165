import { HttpClient } from '@angular/common/http';
import { Component, Inject, OnInit, Optional, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Title } from '@angular/platform-browser';
import { DialogsManagerService } from '@discoverer/dynamic-reports/dialogs';
import { DISCO_FORM_SERVICE_URL, HttpFormsDataService } from '@discoverer/dynamic-forms/dyn-forms/services';
import { CommonDetailsController } from '../../common/common-details-controller';
import { environment } from '@env/environment';
import { OptionDataServiceInterface, OptionListDataServiceInterface } from '@discoverer/dynamic-forms/option-lists/shared-services/data-service';
import { UserDataServiceInterface } from '@discoverer/attachments/services/user-data-service-interface.service';
import { HasuraOptionService } from '@discoverer/dynamic-forms/option-lists/shared-services/hasura-option-service';
import { HasuraOptionListService } from '@discoverer/dynamic-forms/option-lists/shared-services/hasura-option-list-service';
import { TaskService } from '@app/workflow/common/services/graphql-task.service';
import { TaskResponseChainComponent } from '@app/workflow/common/response-chain/response-chain.component';
import { UserDataService } from '@discoverer/app-core';
import { RequestsService } from '@app/workflow/common/services/requests.service';
import { EsearchSettingsService } from '@discoverer/core';
import { Task, TaskStatus } from '@app/workflow/common/models/task';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'app-task-details',
  templateUrl: './task-details.component.html',
  styleUrls: ['../../reports.component.scss', './task-details.component.scss'],
  providers: [
    { provide: HttpFormsDataService, useClass: HttpFormsDataService, deps: [HttpClient] },
    { provide: OptionListDataServiceInterface, useClass: HasuraOptionListService },
    { provide: OptionDataServiceInterface, useClass: HasuraOptionService },
    { provide: UserDataServiceInterface, useExisting: UserDataService },
    RequestsService, TaskService,
    { provide: DISCO_FORM_SERVICE_URL, useValue: `/api/es/form/ats/tasks` }
  ]
})
export class TaskDetailsComponent extends CommonDetailsController<Task> implements OnInit {
  @ViewChild('responseChain') responseChain: TaskResponseChainComponent;

  formServicePath = `/api/es/form/${this.appId}/tasks`;
  appId = 'ats';
  userId;
  isLoading = false;
  constructor(
    private userDataService: UserDataService,
    private requestsService: RequestsService,
    private taskService: TaskService,
    @Optional() @Inject(MAT_DIALOG_DATA) data: any,
    @Optional() dialogRef: MatDialogRef<TaskDetailsComponent>,
    dialogService: DialogsManagerService,
    eSearchSettingsService: EsearchSettingsService,
    defaultForm: HttpFormsDataService,
    dialog: MatDialog,
    snackBar: MatSnackBar,
    titleService: Title,
  ) {
    super(data, dialogRef, dialog,
      eSearchSettingsService, defaultForm, dialogService,
      Task, 'ats', 'tasks', snackBar, titleService);
      this.httpDataService.formServicePath = `/api/es/form/ats/tasks`;
      this.selectedIndex = !!data.selectedIndex ? data.selectedIndex : 0;

  }

  public submitTaskForm(event) {

  }

  public async ngOnInit() {
    const user = await this.userDataService.getUser();
    this.userId = +user.id;
  }

  public get originalRequest() {
    const originalRequest = JSON.parse(this.currentRow.OriginalRequest);
    if (!originalRequest.data.AssigneeId) {
      originalRequest.data.AssigneeId = this.currentRow.AssigneeId;
    }
    originalRequest.data.preFilled = true;
    return originalRequest;
  }

  public get assignedToCurrentUser() {
    return this.userId === this.currentRow.AssigneeId;
  }

  public navigateToEmployeePage() {
    window.open(`${environment.baseURL}/${this.appId}/reports/detail/employees/default/0?Id=${this.currentRow.RelatedToId}`, '_blank');
  }

  public async respondToRequest(option: string) {
    this.isLoading = true;
    const response = await this.requestsService.respondToRequest(this.appId, this.currentRow.Id, option);
    await this.eSearchSettingsService.updateRecordIndexing(this.appId, 'tasks', 'WFReferenceId', [this.currentRow.WFReferenceId.toString()]);
    this.onDetailsChange();
    await this.responseChain.loadWFRelatedTasks(this.appId, this.currentRow.WFReferenceId);
    this.isLoading = false;
  }

  public get isPending(): boolean {
    return this.currentRow.StatusId === TaskStatus.Pending;
  }

  public get options() {
    return this.currentRow.Options.split('|');
  }


  public async assignToMe() {
    const res = await this.taskService.assignTask(this.currentRow.Id, this.userId);
    await this.eSearchSettingsService.updateRecordIndexing(this.appId, 'tasks', 'Id', [this.currentRow.Id.toString()]);
    this.onDetailsChange();
  }

  private onDetailsChange() {
    this.refreshForm();
    this.context.dataService.refresh();
  }


}
