export interface IEmployeeAccess {
    Id: number;
    EmployeeId: number;
    StartDate: Date;
    GivenById: number;
    GivenOn: Date;
    SiteId: number;
    EndDate?: Date;
    GivenBy: string;
    Type: string;
    TypeId: number;
    preFilled: boolean;
}
 // tslint:disable
export class EmployeeAccess {
    Id: number;
    EmployeeId: number;
    StartDate: Date;
    GivenById: number;
    GivenOn: Date;
    SiteId: number;
    EndDate?: Date;
    GivenBy: string;
    Type: string;
    TypeId: number;
    preFilled: boolean;


    constructor(obj: IEmployeeAccess) {
        this.Id = obj.Id;
        this.EmployeeId = obj.EmployeeId;
        this.StartDate = obj.StartDate;
        this.EndDate = obj.EndDate;
        this.GivenById = obj.GivenById;
        this.GivenOn = obj.GivenOn;
        this.SiteId = obj.SiteId;
        this.GivenBy = obj.GivenBy;
        this.Type = obj.Type;
        this.TypeId = obj.TypeId;
        this.preFilled = obj.preFilled;

    }
}
