import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { FormsDataServiceInterface } from '@discoverer/dynamic-forms/dyn-forms/services';
import { AssetFileService } from '../../services/apis/asset-file.service';
import { IAssetFileDto } from '../../../../models/dtos/asset-file.dto';
import Form from './asset-files.form.json';



@Injectable()
export class AssetFileFromService implements FormsDataServiceInterface {
    constructor(private _service: AssetFileService) {
    }

    public getFormByKey(key: string) {
        const form = Form.filter(f => f.Key === key)[0];
        const obj = {
            Key: key,
            Title: form.Title,
            Configuration: JSON.stringify(form.Configuration)
        };
        return of(obj);
    }
    public postFormData(object: any): Observable<IAssetFileDto[]> {
        if (object.Id) {

            var items = object.AttachmentsIds.map(id => {
                return {
                    assetId: object.applicantId,
                    fileId: id
                } as IAssetFileDto;
            }) as IAssetFileDto[];

            return this._service.create(items);
        }
        return of([])
    }
}
