import StatusForm from './applicant-status-form/applicant-status.form.json';
import ResumeForm from './applicant-resume-form/applicant-resume.form.json';
import RatingForm from './applicant-rating-form/applicant-rating.form.json';
import ProfileForm from './applicant-profile-form/applicant-profile.form.json';
import { FormConfiguration } from '../../../../models/form-configuration';
export const getApplicantDetailsForms = (): FormConfiguration[] => {
    return [
        StatusForm,
        ResumeForm,
        RatingForm,
        ProfileForm
    ]
}