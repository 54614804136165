import { Component, Output, EventEmitter, Input } from '@angular/core';
import { FormsDataServiceInterface } from '@discoverer/dynamic-forms/dyn-forms/services';
import { ApplicantAttachmentFormService } from './applicant-attachment-form.service';

@Component({
    selector: 'app-applicant-attachment',
    templateUrl: './applicant-attachment-from.component.html',
    providers: [
        { provide: FormsDataServiceInterface, useClass: ApplicantAttachmentFormService },
    ]
})
export class ApplicantAttachmentComponent {
    @Output()
    isSubmitted = new EventEmitter<boolean>();
    @Input()
    model: any = {};
    constructor() {
    }
    public onSubmit(event) {
        if (event) {
            this.isSubmitted.emit(true);
        }
    }
}
