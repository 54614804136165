import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
    // tslint:disable-next-line:component-selector
    selector: 'confirm-deletion-dialog',
    templateUrl: './confirm-deletion-dialog.html',
    styleUrls: ['./confirm-deletion-dialog.scss']
})
export class ConfirmDeletionDialogComponent {
    constructor(
        public dialogRef: MatDialogRef<ConfirmDeletionDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any) {
    }
    close(userConfirmed = false) {
        this.dialogRef.close(userConfirmed);
    }
}
