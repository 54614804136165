<div id="new-asset">
    <div class="title">
        <span>Asset {{model?.SerialNumber}}</span>
        <mat-icon class="close-button" (click)="close()">close</mat-icon>
    </div>
    <div *ngIf="!data.addNewOnly" class="selection-bar">
        <mat-radio-group (change)="onSelectionChange($event.value)" [(ngModel)]="existingAsset">
            <mat-radio-button class="example-radio-button" *ngFor="let op of options" [value]="op.value">
                {{op.display}}
            </mat-radio-button>
        </mat-radio-group>
    </div>

    <asset-lookup *ngIf="existingAsset" (valueSelected)="onAssetSelection($event)"></asset-lookup>
    <div class="asset-form-wrapper" *ngIf="model">
        <app-dyn-form #dynForm class="form" [key]="data.addNewOnly?'details':'assignment'" [model]="model"
            [showTitle]="false" [isEditMode]="true" (result)="onSubmit($event)">
        </app-dyn-form>
        <div class="buttons">
            <button mat-raised-button class="save-button" color="primary" mat-button (click)="dynForm.submit()">SAVE &
                CLOSE</button>
            <button mat-raised-button class="save-button" color="primary" mat-button
                (click)="addNew = true; dynForm.submit();">SAVE & ADD NEW</button>
            <button mat-raised-button mat-button (click)="close()">CANCEL</button>
        </div>
    </div>
</div>