import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DynTaskComponent } from './start/dyn-task/dyn-task.component';
import { StartComponent } from './start/start.component';

const routes: Routes = [
  { path: 'start', component: StartComponent },
  { path: 'start/form/:formKey', component: DynTaskComponent },
  { path: 'start/:directory', component: StartComponent },
  { path: '', redirectTo: 'start', pathMatch: 'full' }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class WorkflowRoutingModule { }
