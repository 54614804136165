import { Component, Input, Output, EventEmitter } from '@angular/core';
import { FormsDataServiceInterface } from '@discoverer/dynamic-forms/dyn-forms/services';
import { BaseController } from '@discoverer/core';
import { AssetFileFromService } from './asset-files-form.service';

@Component({
    selector: 'app-asset-files',
    templateUrl: './asset-files.component.html',
    styleUrls: ['./asset-files.component.scss'],
    providers: [
        { provide: FormsDataServiceInterface, useClass: AssetFileFromService }
    ]
})
export class AssetFilesComponent extends BaseController {
    @Output() isSubmitted = new EventEmitter();
    @Input()
    set data(value) {
        this._data = value;
        if (this._data.AttachmentsIds) {
            this._data.AttachmentsIds = value.AttachmentsIds.split(',');
        } else {
            this._data.AttachmentsIds = [];
        }
    }
    get data() {
        return this._data;
    }
    @Input()
    public appId = 'ats';
    @Input()
    public set tabId(value) {
        this._tabId = value;
    }
    public get tabId() {
        return this._tabId;
    }
    private _tabId;
    private _data;

    constructor() {
        super();
    }

    public async onSubmit(event) {
        this.isSubmitted.emit(event);
    }

}
