import { Component, OnInit, OnDestroy, Input, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FormsDataServiceInterface } from '@discoverer/dynamic-forms/dyn-forms/services';
import { HttpFormsDataService } from '@app/workflow/common/services/workflow-forms-data-service';
import { takeWhile } from 'rxjs/operators';

@Component({
  selector: 'app-dyn-task',
  templateUrl: './dyn-task.component.html',
  styleUrls: ['./dyn-task.component.scss'],
  providers: [
    { provide: FormsDataServiceInterface, useClass: HttpFormsDataService }
  ]

})
export class DynTaskComponent implements OnInit, OnDestroy {
  isLive = true;
  @Input() key = '';
  @Input() data: any = {};
  @Input() isEditable = true;
  @Input() showTitle = true;

  @Output()
  isSubmitted = new EventEmitter<boolean>();

  constructor(
    private _activeRoute: ActivatedRoute
  ) { }

  ngOnInit() {
    this._activeRoute.params
      .pipe(takeWhile(v => this.isLive))
      .subscribe(
        params => {
          if (params && params.formKey) {
            this.key = params.formKey;
          }
        }
      );
  }

  close() {

  }

  ngOnDestroy(): void {
    this.isLive = false;
  }

}
