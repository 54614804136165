import { HttpClient } from '@angular/common/http';
import { Component, Inject, OnInit, Optional } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Title } from '@angular/platform-browser';
import { DialogsManagerService } from '@discoverer/dynamic-reports/dialogs';
import { DISCO_FORM_SERVICE_URL, HttpFormsDataService } from '@discoverer/dynamic-forms/dyn-forms/services';
import { CommonDetailsController } from '../../common/common-details-controller';
import { NewAssetDialogComponent } from '../new-asset/new-asset-dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Asset } from '@app/ATS/models/asset';
import { EmployeeAssignmentsService } from '../../services/apis/employee-assignment.service';
import { EsearchSettingsService } from '@discoverer/core';
import { UserDataService } from '@discoverer/app-core';
@Component({
  // tslint:disable-next-line:component-selector
  selector: 'app-assset-details',
  templateUrl: './asset-details.component.html',
  styleUrls: ['../../reports.component.scss', './asset-details.component.scss'],
  providers: [
    { provide: HttpFormsDataService, useClass: HttpFormsDataService, deps: [HttpClient] },
    EmployeeAssignmentsService,
    { provide: DISCO_FORM_SERVICE_URL, useValue: `api/es/form/ats/assets` }

  ]
})
export class AssetDetailsComponent extends CommonDetailsController<Asset> implements OnInit {
  public isAdmin = false;
  formServicePath = `/api/es/form/ats/assets`;


  constructor(
    @Optional() @Inject(MAT_DIALOG_DATA) data: any,
    @Optional() dialogRef: MatDialogRef<AssetDetailsComponent>,
    dialogService: DialogsManagerService,
    eSearchSettingsService: EsearchSettingsService,
    defaultForm: HttpFormsDataService,
    dialog: MatDialog,
    snackBar: MatSnackBar,
    titleService: Title,
    private employeeAssignmentsService: EmployeeAssignmentsService,
    private userDataService: UserDataService
  ) {
    super(data, dialogRef, dialog,
      eSearchSettingsService, defaultForm, dialogService,
      Asset, 'ats', 'assets', snackBar, titleService);
  }

  async ngOnInit() {
    const user = await this.userDataService.getUser();
    // this.isAdmin = user.roles.has(AppSettings.AT_ADMIN_ROLE);
  }


  public printToCart(printSectionId: string) {
    let win;
    const content = document.getElementById(printSectionId).innerHTML;
    win = window.open('', '_blank', 'width=600,height=600');
    win.document.open();
    win.document.write
      ('<html><body onload="window.print()">'
        + content + '</html>');
    win.document.close();
  }

  public duplicateAsset() {
    const dialogRef = this.dialog.open(NewAssetDialogComponent, {
      data: {
        addNewOnly: true,
        Description: this.currentRow.Description,
        Name: this.currentRow.Name,
        SiteId: this.currentRow.SiteId,
        TypeId: this.currentRow.TypeId,
        CurrentAssignedEmployeeId: null,
        IsAssignable: this.currentRow.IsAssignable
      },
      width: '960px',
      panelClass: 'white-class-details-dialog',
      disableClose: true
    });
  }
}
