import { HttpClient } from '@angular/common/http';
import { Component, Inject, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Asset } from '@app/ATS/models/asset';
import { UserDataServiceInterface } from '@discoverer/attachments/services/user-data-service-interface.service';
import { DynFormComponent } from '@discoverer/dynamic-forms/dyn-forms/dyn-form/dyn-form.component';
import { FormsDataServiceInterface, HttpFormsDataService } from '@discoverer/dynamic-forms/dyn-forms/services';
import { OptionDataServiceInterface, OptionListDataServiceInterface } from '@discoverer/dynamic-forms/option-lists/shared-services/data-service';
import { HasuraOptionListService } from '@discoverer/dynamic-forms/option-lists/shared-services/hasura-option-list-service';
import { HasuraOptionService } from '@discoverer/dynamic-forms/option-lists/shared-services/hasura-option-service';
import { UserDataService } from '@discoverer/app-core';
import { EsearchSettingsService } from '@discoverer/core';
import { first } from 'rxjs/operators';
import { EmployeeAssignmentsService } from '../../services/apis/employee-assignment.service';
import { AssetService } from '../../services/asset.service';

@Component({
    // tslint:disable-next-line:component-selector
    selector: 'new-asset-dialog',
    templateUrl: './new-asset-dialog.html',
    styleUrls: ['./new-asset-dialog.scss'],
    encapsulation: ViewEncapsulation.None,
    providers: [
        { provide: FormsDataServiceInterface, useClass: HttpFormsDataService, deps: [HttpClient] },
        { provide: OptionListDataServiceInterface, useClass: HasuraOptionListService },
        { provide: OptionDataServiceInterface, useClass: HasuraOptionService },
        { provide: UserDataServiceInterface, useExisting: UserDataService },
        AssetService,
        EmployeeAssignmentsService
    ]
})
export class NewAssetDialogComponent implements OnInit {
    @ViewChild('dynForm') dynForm: DynFormComponent;
    public appId = 'ats';
    public tabId = 'assets';
    public addNew = false;
    public originalData = {} as Asset;
    public serialNumber;
    public model = null;
    public existingAsset = true;
    public options = [{ display: 'Existing', value: true }, { display: 'New', value: false }];

    constructor(
        public dialogRef: MatDialogRef<NewAssetDialogComponent>,
        defaultForm: FormsDataServiceInterface,
        @Inject(MAT_DIALOG_DATA) public data,
        private employeeAssignmentsService: EmployeeAssignmentsService,
        private esearchSettingsService: EsearchSettingsService,
        private assetService: AssetService) {
        this.existingAsset = !data.addNewOnly;
        (defaultForm as HttpFormsDataService).formServicePath = `/api/es/form/${this.appId}/${this.tabId}`;
        Object.assign(this.originalData, data);
    }

    async ngOnInit() {
        if (this.data.addNewOnly) {
            await this.fillNewAssetData(this.data);
        }
    }

    close() {
        this.dialogRef.close();
    }

    public async getNewSerialNumber(): Promise<number> {
        this.serialNumber = await this.assetService.getNextSerialNumber().pipe(first()).toPromise();
        return this.serialNumber;
    }

    public onAssetSelection(value) {
        const data = value.assetData;
        if (data && data.SerialNumber) {
            this.model = Object.assign({
                Description: data.Description,
                Id: data.Id,
                Name: data.Name,
                SerialNumber: data.SerialNumber,
                SiteId: data.SiteId,
                TypeId: data.TypeId,
                CurrentAssignedEmployeeId: this.data.CurrentAssignedEmployeeId,
                IsAssignable: data.IsAssignable
            });
        } else {
            this.model = null;
        }
    }

    public async onSelectionChange(existingAsset: boolean) {
        if (!existingAsset) {
            await this.fillNewAssetData();
        }
    }

    async onSubmit(event: Asset) {
        const EmployeeId = event.CurrentAssignedEmployeeId;
        if (EmployeeId) {
            const obj = {
                EmployeeId,
                AssetId: event.Id,
                StartDate: event.AssignedSince
            };
            await this.employeeAssignmentsService.addEmployeeAssignment(obj).pipe(first()).toPromise();
            await this.esearchSettingsService.updateRecordIndexing('ats', 'assignments', 'EmployeeId', [EmployeeId.toString()]);
            this.serialNumber = null;
        }
        if (this.addNew) {
            this.model = {
                CurrentAssignedEmployeeId: this.originalData.CurrentAssignedEmployeeId,
                SiteId: this.model.SiteId,
                TypeId: this.model.TypeId,
                Name: this.model.Name,
                Description: this.model.Description,
                IsAssignable: this.model.IsAssignable,
                SerialNumber: (await this.getNewSerialNumber()).toString(),
            };
            this.addNew = false;
        } else {
            this.dialogRef.close(event);
        }
    }

    private async fillNewAssetData(data?) {
        this.model = Object.assign({
            Description: data ? data.Description : null,
            Name: data ? data.Name : null,
            SerialNumber: (await this.getNewSerialNumber()).toString(),
            SiteId: data ? data.SiteId : this.originalData.SiteId,
            TypeId: data ? data.TypeId : null,
            CurrentAssignedEmployeeId: data ? null : this.originalData.CurrentAssignedEmployeeId,
            IsAssignable: data ? data.IsAssignable : true
        });
    }

}
