import { Injectable } from '@angular/core';
import { first } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { FormsDataServiceInterface } from '@discoverer/dynamic-forms/dyn-forms/services';
import Form from './applicant-interview.form.json';
import { ApplicantInterviewService } from '../../../services/apis/applicant-interview.service';
import { IApplicantInterviewDto } from '../../../../../models/dtos/applicant-interview.dto';

@Injectable()
export class ApplicantInterviewFormService implements FormsDataServiceInterface {
    constructor(private _applicantInterviewApiService: ApplicantInterviewService) {
    }

    public getFormByKey(key: string) {
        const form = Form.filter(f => f.Key === key)[0];
        const obj = {
            Key: key,
            Title: form.Title,
            Configuration: JSON.stringify(form.Configuration)
        };
        return of(obj).delay(1);
    }

    postFormData(interview: IApplicantInterviewDto): Observable<IApplicantInterviewDto> {
        if (interview) {
            if (interview.questions) {
                interview.questions = JSON.stringify(interview.questions);
            }
            if (interview.id)
                return this._applicantInterviewApiService.update(interview.id, interview);

            return this._applicantInterviewApiService.create(interview);
        }
    }
}
