export interface IAsset {
    Id: number;
    Description: string;
    SerialNumber: string;
    Name: string;
    SiteId: number;
    TypeId: number;
    CurrentAssignedEmployee: string;
    CurrentAssignedEmployeeId: number;
    AssignedSince: Date;
    IsAvailable: boolean;
    AttachmentsIds: string;
    IsAssignable: boolean;
}
 // tslint:disable
export class Asset {
    Id: number;
    Description: string;
    SerialNumber: string;
    Name: string;
    SiteId: number;
    TypeId: number;
    CurrentAssignedEmployee: string;
    CurrentAssignedEmployeeId: number;
    AssignedSince: Date;
    IsAvailable: boolean;
    AttachmentsIds: string;
    IsAssignable: boolean;


    constructor(obj: IAsset) {
        this.Id = obj.Id;
        this.Name = obj.Name;
        this.Description = obj.Description;
        this.SerialNumber = obj.SerialNumber;
        this.SiteId = obj.SiteId;
        this.TypeId = obj.TypeId;
        this.CurrentAssignedEmployee = obj.CurrentAssignedEmployee;
        this.CurrentAssignedEmployeeId = obj.CurrentAssignedEmployeeId;
        this.AssignedSince = obj.AssignedSince;
        this.IsAvailable = obj.IsAvailable;
        this.AttachmentsIds = obj.AttachmentsIds;
        this.IsAssignable = obj.IsAssignable;
    }
}
