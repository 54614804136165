<div class="controls">
    <button mat-raised-button *ngIf="!isEditMode" color="accent" mat-button
        (click)="isEditMode = true; data.isEditMode=true">EDIT</button>
    <button color="warn" mat-button class="detail-button" (click)="deleteApplicant()" *ngIf="!isEditMode">
        DELETE
    </button>

    <div style="display: flex;">
        <mat-spinner *ngIf="isLoading" [diameter]="30" color="primary"></mat-spinner>
        <button mat-raised-button *ngIf="isEditMode" color="primary" mat-button [disabled]="isLoading"
            (click)="save()">SAVE</button>
    </div>

    <button mat-flat-button *ngIf="isEditMode" color="secondary" mat-button
        (click)="isEditMode = false; data.isEditMode=false">CANCEL</button>
</div>
<div class="applicant-form-wrapper">
    <app-dyn-form #dynForm class="fixed-field-height" [key]="formKey" [model]="data" [showTitle]="false"
        [isEditMode]="isEditMode" (result)="onSubmit($event)">
    </app-dyn-form>
</div>