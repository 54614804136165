import { Apollo } from 'apollo-angular';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { IEmployeeFileDto } from '../../../../models/dtos/employee-file.dto';
import { FormsDataServiceInterface } from '@discoverer/dynamic-forms/dyn-forms/services';
import Form from './employee-file.form.json';
import { EmployeeFileService } from '../../services/apis/employee-file.service';

@Injectable()
export class EmployeeFileFromService implements FormsDataServiceInterface {
    constructor(private apollo: Apollo, private _employeeFileService: EmployeeFileService) {
    }

    public getFormByKey(key: string) {
        const form = Form.filter(f => f.Key === key)[0];
        const obj = {
            Key: key,
            Title: form.Title,
            Configuration: JSON.stringify(form.Configuration)
        };
        return of(obj).delay(1);
    }

    public postFormData(object: any): Observable<IEmployeeFileDto[]> {

        if (object.Id) {

            var items = object.attachmentsIds.map(id => {
                return {
                    employeeId: object.applicantId,
                    fileId: id
                } as IEmployeeFileDto;
            }) as IEmployeeFileDto[];

            return this._employeeFileService.create(items);
        }

        return of([])
    }

}
