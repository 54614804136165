import { GraphQlOptionDataServiceInterface } from '@discoverer/dynamic-forms/option-lists/shared-services/data-service';
import { Inject, forwardRef, Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { Apollo } from 'apollo-angular';
import { map } from 'rxjs/operators';
import gql from 'graphql-tag';

@Injectable()
export class GraphQueryOptionService implements GraphQlOptionDataServiceInterface {
    constructor(@Inject(forwardRef(() => Apollo)) private apollo: Apollo) {

    }
    getByOptions(query: string, filterBy?: any): Observable<any[]> {
        try {
            const queryObservable = this.apollo.query({
                query: gql`${query} `,
                variables: filterBy !== undefined ? { filterBy } : {}
            });
            return queryObservable.pipe(map((x: any) =>
                x.data[Object.keys(x.data)[0]]
            ));
        } catch (ex) {
            return of();
        }
    }
}
