import { Inject, Injectable, InjectionToken } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { FilesServiceInterface } from '@discoverer/dynamic-forms/dyn-forms/dyn-upload/files-service.interface';
import { Observable } from 'rxjs';
import { FILES_SERVICE_URL, FilesService } from '../../../../discoverer/dynamic-reports/disco-details/files-service';
import { environment } from '../../../../../environments/environment.dev';




@Injectable()
export class AtsPublicFilesService implements FilesServiceInterface {
    private _defaultHeaders: HttpHeaders;
    private _filesServiceUrl = '/api/es/attachments/ats';

    constructor(private http: HttpClient) {

        this._defaultHeaders = new HttpHeaders({
            'Authorization': 'Bearer ' + environment.auth.publicAccessToken,
        });
    }

    public uploadFiles(files, folderName: string): Observable<any> {
        return this.http.post(`${this._filesServiceUrl}/${folderName}`, files, { headers: this._defaultHeaders });
    }

    public getFile(id): Observable<any> {
        return this.http.get(`${this._filesServiceUrl}/${id}`, {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
            }), responseType: 'blob'
        });
    }

    public getFilesInfo(ids: number[]): Observable<any> {
        return this.http.post(`${this._filesServiceUrl}/info`, ids, { headers: this._defaultHeaders });
    }

    public deleteFile(id): Observable<any> {
        return this.http.delete<any>(`${this._filesServiceUrl}/${id}`, { headers: this._defaultHeaders });
    }
}

