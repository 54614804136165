import { HttpClient } from '@angular/common/http';
import { Component, Inject, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { UserDataServiceInterface } from '@discoverer/attachments/services/user-data-service-interface.service';
import { DynFormComponent } from '@discoverer/dynamic-forms/dyn-forms/dyn-form/dyn-form.component';
import { DynFormsUserDataServiceInterface, FormsDataServiceInterface, HttpFormsDataService } from '@discoverer/dynamic-forms/dyn-forms/services';
import { OptionDataServiceInterface, OptionListDataServiceInterface } from '@discoverer/dynamic-forms/option-lists/shared-services/data-service';
import { HasuraOptionListService } from '@discoverer/dynamic-forms/option-lists/shared-services/hasura-option-list-service';
import { HasuraOptionService } from '@discoverer/dynamic-forms/option-lists/shared-services/hasura-option-service';
import { UserDataService } from '@discoverer/app-core';
import { EsearchSettingsService } from '@discoverer/core';

@Component({
    // tslint:disable-next-line:component-selector
    selector: 'new-task-dialog',
    templateUrl: './new-task-dialog.html',
    styleUrls: ['./new-task-dialog.scss'],
    providers: [
        { provide: FormsDataServiceInterface, useClass: HttpFormsDataService, deps: [HttpClient] },
        { provide: OptionListDataServiceInterface, useClass: HasuraOptionListService },
        { provide: OptionDataServiceInterface, useClass: HasuraOptionService },
        { provide: UserDataServiceInterface, useExisting: UserDataService },
        { provide: DynFormsUserDataServiceInterface, useExisting: UserDataService }

    ]
})
export class NewTaskDialogComponent {
    @ViewChild('dynForm') dynForm: DynFormComponent;

    public appId = 'ats';
    public tabId = 'tasks';

    constructor(
        public dialogRef: MatDialogRef<NewTaskDialogComponent>,
        defaultForm: FormsDataServiceInterface,
        private esearchSettingsService: EsearchSettingsService,
        private matSnackBar: MatSnackBar,
        @Inject(MAT_DIALOG_DATA) public data) {
        (defaultForm as HttpFormsDataService).formServicePath = `/api/es/form/${this.appId}/${this.tabId}`;
    }

    close() {
        this.dialogRef.close();
    }

    async onSubmit(event: { isDone: boolean }) {
        setTimeout(async () => {
            await this.esearchSettingsService.indexTabData(this.appId, this.tabId);
            if (event.isDone) {
                this.dialogRef.close(event);
            } else {
                this.data.TypeId = null;
                this.data.Description = null;
                this.data = Object.assign({}, this.data);
            }
        }, 2000);

        this.matSnackBar.open('Saved Successfully.', '', {
            duration: 3000,
        });

    }

}
