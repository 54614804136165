import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { IApplicantAttachmentDto } from '../../../../models/dtos/applicant-attachment.dto';
import { ApiCallerService } from './api-caller.service';

const BASE_URL = `/api/applicant-attachments`;

@Injectable()
export class ApplicantAttachmentService {
    constructor(private _apiCallerService: ApiCallerService) { }

    public create(applicantId: number, list: IApplicantAttachmentDto[]): Observable<IApplicantAttachmentDto[]> {
        return this._apiCallerService.post<IApplicantAttachmentDto[]>(BASE_URL, {applicantId : applicantId, list :list }, new HttpHeaders());

    }
    public delete(id: number): Observable<boolean> {
        return this._apiCallerService.delete<boolean>(`${BASE_URL}/${id}`, new HttpHeaders());
    }
}

