import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReportsRoutingModule } from './reports-routing.module';
import { DiscoDetailsModule, DiscoFormModule, DynamicReportsModule } from '@discoverer/dynamic-reports';
import { MaterialModule } from '@discoverer/MaterialModule'
import { GridModule } from '@progress/kendo-angular-grid';
import { WidgetsModule } from '@app/components/widgets.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTabsModule } from '@angular/material/tabs';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { DiscussionNotesService } from './services/apis/discussion-note.service';
import { NotesModule } from '@discoverer/attachments/notes.module';
import { NotesService } from '@discoverer/attachments/services/notes.service';
import { FilesServiceInterface } from '@discoverer/dynamic-forms/dyn-forms/dyn-upload/files-service.interface';
import { DynFormsModule } from '@discoverer/dynamic-forms/dyn-forms/dyn-forms.module';
import { WorkflowModule } from '@app/workflow/workflow.module';
import { AssetFilesComponent } from './assets/asset-files-form/asset-files.component';
import { AssetActionsComponent } from './assets/actions/asset-actions.component';
import { AssetDetailsComponent } from './assets/asset-details/asset-details.component';
import { AssetHistoryComponent } from './assets/asset-details/asset-history/asset-history.component';
import { AssetLookupComponent } from './assets/new-asset/asset-lookup.component';
import { NewAssetDialogComponent } from './assets/new-asset/new-asset-dialog';
import { NewEquipmentDialogComponent } from './assets/new-assignment/new-equipment-dialog';
import { EmployeeActionsComponent } from './employees/actions/employee-actions.component';
import { AccessDialogComponent } from './employees/employee-details/accessible-apps/access-dialog/access-dialog';
import { AccessibleAppsComponent } from './employees/employee-details/accessible-apps/accessible-apps.component';
import { AssignedAssetsComponent } from './employees/employee-details/assigned-assets/assigned-assets.component';
import { AssignmentDialogComponent } from './employees/employee-details/assigned-assets/assignment-dialog/assignment-dialog';
import { EmployeeDetailsComponent } from './employees/employee-details/employee-details.component';
import { RelatedTasksComponent } from './employees/employee-details/related-tasks/related-tasks.component';
import { TaskDialogComponent } from './employees/employee-details/related-tasks/task-dialog/task-dialog';
import { EmployeeFilesComponent } from './employees/employee-file-from/employee-file-from.component';
import { NewEmployeeDialogComponent } from './employees/new-employee/new-employee-dialog';
import { NewAccessDialogComponent } from './new-access/new-access-dialog';
import { TaskActionsComponent } from './tasks/actions/task-actions.component';
import { NewTaskDialogComponent } from './tasks/new-task/new-task-dialog';
import { TaskDetailsComponent } from './tasks/task-details/task-details.component';
import { FilesService, FILES_SERVICE_URL } from '@discoverer/dynamic-reports/disco-details/files-service';
import { DiscovererWidgetsModule } from '../../../discoverer/core/discoverer.module';
import { AtsPublicFilesService } from './services/public-files.service';
import { EmployeeFileService } from './services/apis/employee-file.service';
import { AssetFileService } from './services/apis/asset-file.service';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { ApiCallerService } from './services/apis/api-caller.service';
import { EmployeeFileFromService } from './employees/employee-file-from/employee-file-form.service';
import { AssetFileFromService } from './assets/asset-files-form/asset-files-form.service';
import { ApplicantProfileFormComponent } from './applicants/applicant-details/applicant-profile-form/applicant-profile-form.component';
import { ApplicantDetailsComponent } from './applicants/applicant-details/applicant-details.component';
import { ImportApplicantFormService } from './applicants/import-applicant/import-applicant-form.service';
import { ImportApplicantComponent } from './applicants/import-applicant/import-applicant.component';
import { ApplicantActionsComponent } from './applicants/applicant-actions/applicants-actions.component';
import { ApplicantInterviewFormService } from './applicants/applicant-details/applicant-interview-form/applicant-interview-form.service';
import { ImportApplicantDialogComponent } from './applicants/applicant-dialogs/import-applicant-dialog/import-applicant-dialog';
import { NewApplicantDialogComponent } from './applicants/applicant-dialogs/new-applicant-dialog/new-applicant-dialog';
import { NewApplicantImportLinkDialogComponent } from './applicants/applicant-dialogs/new-applicant-import-link-dialog/new-applicant-import-link-dialog';
import { NewApplicantComponent } from './applicants/new-applicant/new-applicant.component';
import { ApplicantInterviewService } from './services/apis/applicant-interview.service';
import { ApplicantService } from './services/apis/applicant.service';
import { ApplicantStatusComponent } from './/applicants/applicant-details/applicant-status-form/applicant-status-form.component';
import { ApplicantTagsComponent } from './applicants/applicant-details/applicant-tags/applicant-tags.component';
import { ApplicantRatingComponent } from './applicants/applicant-details/applicant-rating-form/applicant-rating-form.component';
import { ApplicantInterviewFormComponent } from './/applicants/applicant-details/applicant-interview-form/applicant-interview-form.component';
import { ApplicantTagsDialogComponent } from './applicants/applicant-dialogs/applicant-tags-dialog/applicant-tags-dialog';
import { ApplicantFiltersComponent } from './applicants/applicant-filters/applicants-filters.component';
import { ApplicantAttachmentComponent } from './/applicants/applicant-details/applicant-attachment-form/applicant-attachment-from.component';
import { ApplicantResumeComponent } from './applicants/applicant-details/applicant-resume-form/applicant-resume-form.component';
import { NewImportLinkFormService } from './applicants/applicant-dialogs/new-applicant-import-link-dialog/new-applicant-import-link-from.service';
import { ApplicantAttachmentService } from './services/apis/applicant-attachment.service';
import { ApplicantAttachmentFormService } from './applicants/applicant-details/applicant-attachment-form/applicant-attachment-form.service';
import { ApplicantTagService } from './services/apis/applicant-tag.service';
import { ApplicantDetailsFromService } from './applicants/applicant-details/applicant-details-form.service';


@NgModule({
  declarations: [
    ApplicantProfileFormComponent,
    ApplicantDetailsComponent,
    NewApplicantComponent,
    ImportApplicantComponent,
    ApplicantStatusComponent,
    ApplicantResumeComponent,
    ApplicantAttachmentComponent,
    ApplicantTagsComponent,
    ApplicantInterviewFormComponent,
    ApplicantRatingComponent,
    NewApplicantDialogComponent,
    ApplicantTagsDialogComponent,
    NewApplicantImportLinkDialogComponent,
    ImportApplicantDialogComponent,
    ApplicantActionsComponent,
    ApplicantFiltersComponent,
    EmployeeDetailsComponent,
    NewEmployeeDialogComponent,
    EmployeeActionsComponent,
    TaskDetailsComponent,
    NewTaskDialogComponent,
    TaskActionsComponent,
    AssetDetailsComponent,
    NewAssetDialogComponent,
    AssetLookupComponent,
    AssetActionsComponent,
    AssignedAssetsComponent,
    AssetHistoryComponent,
    AssignmentDialogComponent,
    AccessibleAppsComponent,
    RelatedTasksComponent,
    AccessDialogComponent,
    NewAccessDialogComponent,
    TaskDialogComponent,
    NewEquipmentDialogComponent,
    EmployeeFilesComponent,
    AssetFilesComponent,
  ],
  imports: [
    CommonModule,
    ReportsRoutingModule,
    DynamicReportsModule,
    MaterialModule,
    GridModule,
    DynFormsModule,
    DiscoFormModule,
    WidgetsModule,
    FormsModule,
    ReactiveFormsModule,
    FormsModule,
    MatTabsModule,
    MatSlideToggleModule,
    DiscovererWidgetsModule,
    PdfViewerModule,
    NotesModule.forRoot({
      notesControllerUri: '/api/es/notes/ats',
      attachmentsControllerUri: '/api/es/notes/ats/attachments'
    }),
    WorkflowModule.forRoot({ formControllerURL: '/api/es/forms/ats' }),
    DiscoDetailsModule.forRoot('/api/es/uploads/ats'),
  ],
  providers: [
    NotesService,
    ApplicantDetailsFromService,
    NewImportLinkFormService,
    ImportApplicantFormService,
    EmployeeFileFromService,
    AtsPublicFilesService,
    ApplicantInterviewFormService,
    DiscussionNotesService,
    ApplicantService,
    ApiCallerService,
    AssetFileService,
    ApplicantInterviewService,
    AssetFileFromService,
    ApplicantAttachmentService,
    EmployeeFileService,
    ApplicantTagService,
    ApplicantAttachmentFormService,
    { provide: FilesServiceInterface, useClass: FilesService },
    { provide: FILES_SERVICE_URL, useValue: '/api/es/attachments/ats' }

  ],
  entryComponents: [
    NewApplicantDialogComponent,
    ApplicantTagsDialogComponent,
    NewApplicantImportLinkDialogComponent,
    ImportApplicantDialogComponent,
    NewEmployeeDialogComponent,
    NewTaskDialogComponent,
    NewAssetDialogComponent,
    AssignedAssetsComponent,
    AccessibleAppsComponent,
    RelatedTasksComponent,
    AssignmentDialogComponent,
    AccessDialogComponent,
    TaskDialogComponent,
    NewAccessDialogComponent,
    NewApplicantComponent,
    ImportApplicantComponent,
    NewEquipmentDialogComponent],
  exports: [
    ApplicantDetailsComponent,
    GridModule,
    DiscovererWidgetsModule,
    ApplicantProfileFormComponent

  ]
})
export class ReportsModule { }
