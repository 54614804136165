import { Component, Output, EventEmitter, Input, OnInit, ChangeDetectorRef } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ApplicantTagsDialogComponent } from '../../applicant-dialogs/applicant-tags-dialog/applicant-tags-dialog';
import { FormsDataServiceInterface } from '../../../../../../discoverer/dynamic-forms/dyn-forms/services/forms-data-service.interface';
import { ApplicantDetailsFromService } from '../applicant-details-form.service';

@Component({
    selector: 'app-applicant-tags',
    templateUrl: './applicant-tags.component.html',
    styleUrls: ['./applicant-tags.component.scss']

})
export class ApplicantTagsComponent implements OnInit {
    @Output()
    isSubmitted = new EventEmitter<boolean>();
    @Input()
    model: any = {};
    public selectedIds: number[] = [];
    public optionsListId: number = 10;
    public showChipList: boolean = true;
    constructor(private dialog: MatDialog, private cdr: ChangeDetectorRef
    ) {
    }
    ngOnInit(): void {
        this.model.isEditMode = false;
        this.selectedIds = this.model.tagsIds;
    }

    public onSubmit(event) {
        if (event) {
            this.isSubmitted.emit(true);
        }
    }

    public onEdit() {
        this.model.isEditMode = true;
        const dialogRef = this.dialog.open(ApplicantTagsDialogComponent, {
            data: this.model,
            width: '80vw',
            height: '50vh',
            maxWidth: '800px',
            panelClass: 'white-class-details-dialog',
        });

        dialogRef.afterClosed().subscribe(async x => {
            this.model.isEditMode = false;
            this.selectedIds = this.model.tagsIds;
            this.recreateChipList();
            this.cdr.detectChanges();
        });
    }

    private recreateChipList() {
        this.showChipList = false;
        this.cdr.detectChanges();
        this.showChipList = true;
        this.cdr.detectChanges();
    }
}
