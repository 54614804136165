import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiCallerService } from './api-caller.service';


const BASE_URL = `/api/es/ats/employee-assignments`;

@Injectable()
export class EmployeeAssignmentsService {

    constructor(private _apiCallerService: ApiCallerService) { }

    public addEmployeeAssignment(dto): Observable<boolean> {
        return this._apiCallerService.post<boolean>(`${BASE_URL}`, dto );
    }
}
