
import { Injectable } from '@angular/core';
import { AbstractNavigationItemsService, Navigation } from '@discoverer/app-core';
import { FuseNavigationItem } from '@discoverer/fuse/components/navigation';
import { Observable, of } from 'rxjs';

export const navItems: FuseNavigationItem[] = [
    {
        id: 'applicants',
        title: 'Applicants',
        type: 'basic',
        icon: '',
        link: '/ats/reports/applicants/default'
    },
    {
        id: 'tasks',
        title: 'Tasks',
        type: 'basic',
        icon: '',
        link: '/ats/reports/tasks/default'
    },
    {
        id: 'employees',
        title: 'Employees',
        type: 'basic',
        icon: '',
        link: '/ats/reports/employees/default'
    },
    {
        id: 'assets',
        title: 'Assets',
        type: 'basic',
        icon: '',
        link: '/ats/reports/assets/default'
    },
    {
        id: 'option-list',
        title: 'Option-Lists',
        type: 'basic',
        icon: 'heroicons_outline:database',
        link: '/ats/lists'
    }
];

@Injectable()
export class NavigationItemsService extends AbstractNavigationItemsService  {
    loadItems(option: any): Promise<Navigation> {
        throw new Error('Method not implemented.');
    }
    getNavigationItems(): Observable<Navigation> {
        return of({
            items: navItems,
            mainTitle: 'ATS'
        });
    }
}

