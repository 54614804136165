import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { WorkflowRoutingModule } from './workflow-routing.module';
import { FormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatExpansionModule } from '@angular/material/expansion';
import { GridModule } from '@progress/kendo-angular-grid';
import { StartComponent } from './start/start.component';
import { MessageDialogComponent } from './common/message-dialog/message-dialog';
import { DynFormsModule } from '@discoverer/dynamic-forms/dyn-forms/dyn-forms.module';
import { WorkflowFormsConfig, WORKFLOW_FORMS_CONFIG } from './common/services/workflow-forms-data-service';
import { MatDialogModule } from '@angular/material/dialog';
import { DynTaskComponent } from './start/dyn-task/dyn-task.component';
import { TaskCompletionComponent } from './task-completion/task-completion.component';
import { TaskResponseChainComponent } from './common/response-chain/response-chain.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

@NgModule({
  declarations: [
    StartComponent,
    DynTaskComponent,
    MessageDialogComponent,
    TaskCompletionComponent,
    TaskResponseChainComponent
  ],
  imports: [
    CommonModule,
    DynFormsModule,
    WorkflowRoutingModule,
    FormsModule,
    MatInputModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatSelectModule,
    MatIconModule,
    MatSidenavModule,
    MatExpansionModule,
    GridModule,
    MatDialogModule,
    MatProgressSpinnerModule
  ],
  exports: [
    StartComponent,
    DynTaskComponent,
    TaskCompletionComponent,
    TaskResponseChainComponent
  ],
  entryComponents: [MessageDialogComponent]
})

export class WorkflowModule {
  static forRoot(config: WorkflowFormsConfig): ModuleWithProviders<WorkflowModule> {
    return {
      ngModule: WorkflowModule,
      providers: [
        { provide: WORKFLOW_FORMS_CONFIG, useValue: config },
      ]
    };
  }
}
