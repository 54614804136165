import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { IImportApplicantDto } from '../../../../models/dtos/import-applicant.dto';
import { IApplicantDto } from '../../../../models/dtos/applicant.dto';
import { environment } from '@env/environment';
import { ApiCallerService } from './api-caller.service';
import { IDiscussionNoteDto } from '../../../../models/dtos/discussion-note.dto';

const BASE_URL = `/api/applicants`;
@Injectable()
export class ApplicantService {
    constructor(private _apiCallerService: ApiCallerService) { }
    public importList(isPublicApplications: boolean, dtos: IImportApplicantDto[]): Observable<any> {
      
        let headers = new HttpHeaders();

        if (isPublicApplications) {
            headers = headers.append('Authorization', 'Bearer ' + environment.auth.publicAccessToken);
        }

        return this._apiCallerService.post<IImportApplicantDto[]>(`${BASE_URL}/import`, dtos, headers);
    }
    public create(dto: IApplicantDto): Observable<IApplicantDto> {
        return this._apiCallerService.post<IApplicantDto>(BASE_URL, dto);
    }

    public update(id: number, dto: IApplicantDto): Observable<IApplicantDto> {
        return this._apiCallerService.put<IApplicantDto>(`${BASE_URL}/${id}`, dto);
    }

    public updateDiscussionListId(id: number, listId: number): Observable<IApplicantDto> {
        return this._apiCallerService.put<IApplicantDto>(`${BASE_URL}/${id}/discussion-list-id`, { ListId: listId });
    }

    public createApplicantNotesForLog(id: number, logListId: number, notes: IDiscussionNoteDto[]): Observable<IApplicantDto> {
        return this._apiCallerService.post<IApplicantDto>(`${BASE_URL}/${id}/log-notes`, { ListId: logListId, Notes: notes });
    }

    public delete(id: number): Observable<IApplicantDto> {
        return this._apiCallerService.delete<IApplicantDto>(`${BASE_URL}/${id}`);
    }
}
