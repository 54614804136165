import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { IEmployeeFileDto } from '../../../../models/dtos/employee-file.dto';
import { ApiCallerService } from './api-caller.service';

const BASE_URL = `/api/employee-files`;

@Injectable()
export class EmployeeFileService {

    constructor(private _apiCallerService: ApiCallerService) { }


    public create(dtos: IEmployeeFileDto[]): Observable<IEmployeeFileDto[]> {
        return this._apiCallerService.post<IEmployeeFileDto[]>(`${BASE_URL}`, dtos );
    }
}
