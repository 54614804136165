<disco-details #details appId="ats" tabId="assets" (onDelete)="deleteRow($event)" (context)="setContext($event)"
  [formServicePath]="formServicePath">
  <ng-template #headerTemplate let-context>
    <div class="title-header">
      <div>
        <div style="color: var(--dark-600); text-transform: uppercase; font-size: 14px;">Asset</div>
        <div style="align-items: center; column-gap: 20px;" class="row">
          <h1 id="printable-section" class="title-header">{{currentRow.SerialNumber}}</h1>
          <mat-icon (click)="printToCart('printable-section')" class="material-icons">print</mat-icon>
        </div>
      </div>
      <div class="header-buttons">
        <div class="action-buttons">
          <app-disco-form-control *ngIf="selectedIndex == 0 " class="controls" title="EDIT" [form]="detailForm">
          </app-disco-form-control>
          <button mat-raised-button color="primary" (click)="duplicateAsset()">Duplicate</button>
        </div>
      </div>
    </div>
  </ng-template>
  <ng-template #bodyTemplate let-context>
    <ng-container *ngIf="context?.data[0] as rowDetails">
      <mat-tab-group [(selectedIndex)]="selectedIndex">
        <mat-tab label="Info">
          <div class="row">
            <mat-card class="card asset-details">
              <!-- <app-disco-form #detailForm appId="ats" tabId="assets" formKey="details" [data]="rowDetails">
              </app-disco-form> -->
            </mat-card>
            <mat-card class="files-card">
              <app-asset-files [appId]="appId" tabId="assets" [data]="currentRow" (isSubmitted)="onSubmit($event);">
            </app-asset-files>
            </mat-card>
          </div>
        </mat-tab>
        <mat-tab *ngIf="currentRow.IsAssignable" label="Assignments">
          <asset-history [asset]="currentRow"></asset-history>
        </mat-tab>
      </mat-tab-group>
    </ng-container>
  </ng-template>
</disco-details>