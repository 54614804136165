import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { IApplicantTagDto } from '../../../../models/dtos/applicant.tag.dto';
import { ApiCallerService } from './api-caller.service';

const BASE_URL = `/api/applicant-tags`;

@Injectable()
export class ApplicantTagService {
    constructor(private _apiCallerService: ApiCallerService) { }
    public create(applicantId:number,list : IApplicantTagDto[]): Observable<IApplicantTagDto[]> {
        return this._apiCallerService.post<IApplicantTagDto[]>(BASE_URL, {applicantId : applicantId, list  :list });
    }
}

