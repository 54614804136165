import { Component, Output, EventEmitter, Input, ViewChild } from '@angular/core';
import { FormsDataServiceInterface } from '@discoverer/dynamic-forms/dyn-forms/services';
import { UserDataService } from '@discoverer/app-core';
import { IDiscussionNoteDto } from '@app/ATS/models/dtos/discussion-note.dto';
import { ApplicantDetailsFromService } from '../applicant-details-form.service';
import { IApplicantDto } from '../../../../../models/dtos/applicant.dto';
import { ApplicantService } from '../../../services/apis/applicant.service';

@Component({
    selector: 'app-applicant-status-form',
    templateUrl: './applicant-status-form.component.html',
    providers: [
        { provide: FormsDataServiceInterface, useClass: ApplicantDetailsFromService }
    ]
})
export class ApplicantStatusComponent {
    @Output()
    isSubmitted = new EventEmitter<boolean>();

    @Input()
    public model: any = {};
    public user: any;
    public isLoading = false;

    constructor(
        private _applicantService: ApplicantService,
        private _userService: UserDataService
    ) {
        this._userService.loggedInUser.subscribe((userData) => this.user = userData);
    }

    public onChange() {
        this.isLoading = true;
    }
    public async onSubmit(applicant: IApplicantDto) {

        if (applicant) {
            this.isLoading = true;
            let listId = this.model.logListId;
            const status = applicant?.status ?? ''

            var notes: IDiscussionNoteDto[] = [{
                body: 'Changed Status to ' + status,
                notesListId: listId,
                createdOn: new Date().toString(),
                createdById: +this.user.id
            }];

            this._applicantService.createApplicantNotesForLog(applicant.id, this.model.logListId, notes).subscribe(x => {
                this.isSubmitted.emit(true);
                this.isLoading = false;
            });
        }
        else
            this.isLoading = false;


    }
}
