import { Injectable, Inject, InjectionToken } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { NotesServiceInterface } from './notes-service-interface.service';
import { INote } from '../models/note.model';

export class NotesConfig {
    notesControllerUri: string;
    attachmentsControllerUri: string;
    notificationsControllerUri?: string;
}
export const NOTES_SERVICE_CONFIG = new InjectionToken<NotesConfig>('NotesControllerUri');

@Injectable()
export class NotesService implements NotesServiceInterface {
    constructor(
        private httpClient: HttpClient,
        @Inject(NOTES_SERVICE_CONFIG) private config: NotesConfig,

    ) {
    }
    public getListNotes(listId: number): Promise<INote[]> {
        return this.httpClient.get<any>(`${this.config.notesControllerUri}?listId=${listId}`).toPromise();
    }
    public addNote(formData: FormData): Promise<INote> {
        return this.httpClient.post<INote>(`${this.config.notesControllerUri}`, formData).toPromise<INote>();
    }
    public deleteNote(id: number): Observable<number> {
        return this.httpClient.delete<number>(`${this.config.notesControllerUri}/${id}`);
    }
    public downloadAttachment(id: number): Promise<any> {
        return this.httpClient.get(`${this.config.attachmentsControllerUri}/${id}`, {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
            }), responseType: 'blob'
        }).toPromise();
    }
    public deleteAttachment(id: number): Observable<number> {
        return this.httpClient.delete<number>(`${this.config.attachmentsControllerUri}/${id}`);
    }
    public addNoteNotification(noteId: number, entityId: number): Promise<boolean> | boolean {
        if (this.config.notificationsControllerUri) {
            return this.httpClient
                .post<boolean>(`${this.config.notificationsControllerUri}?noteId=${noteId}&entityId=${entityId}`, {}).toPromise<boolean>();
        }
        return true;
    }
}
