import { Component, Input, Output, EventEmitter, ViewChild, OnInit } from '@angular/core';
import { FormsDataServiceInterface } from '@discoverer/dynamic-forms/dyn-forms/services/forms-data-service.interface';
import { Applicant } from '@app/ATS/models/applicant';
import { ApplicantDetailsFromService } from '../applicant-details-form.service';
import { SubmitDynFormBloc } from '@discoverer/dynamic-forms/dyn-forms/dyn-form/dyn-form.blocs';

@Component({
  selector: 'app-applicant-profile-form',
  templateUrl: './applicant-profile-form.component.html',
  styleUrls: ['./applicant-profile-form.component.scss'],
  providers: [
    { provide: FormsDataServiceInterface, useClass: ApplicantDetailsFromService }
  ]
})
export class ApplicantProfileFormComponent implements OnInit {

  @Input() set data(value: Applicant) {
    this._data = value;
    this._data.isEditMode = false;
    this.isEditMode = false;
  }
  get data(): Applicant {
    return this._data;
  }

  @Input()
  formKey: string;

  @Input()
  appId = 'ats';

  @Input()
  tabId = 'applicants';
  public isLoading = false;
  @Output() isSubmitted = new EventEmitter();
  @Output() isDeleting = new EventEmitter();
  isEditMode = false;
  @ViewChild('dynForm', { static: false }) dynForm;


  private _data = new Applicant();

  constructor(
  ) {





  }
  ngOnInit(): void {

  }

  public save() {
    this.isLoading = true;

    if (!this.dynForm.form.valid) {
      this.isLoading = false;
    }
    this.dynForm.submit();
  }


  onSubmit(event) {
    if (event) {
      this.isEditMode = false;
      this.isLoading = false;
      this.isSubmitted.emit(true);
    }
    this.isLoading = false;

  }


  public async deleteApplicant() {
    this.isDeleting.emit(true);
  }
}
