
<div class="title">
    <span>Import Applicants</span>
    <mat-icon class="close-button" (click)="close()">close</mat-icon>
</div>
<div class="mt-10" style="height: calc(90% - 64px); overflow: auto; ">
    <app-dyn-form #dynForm class="form" [key]="'import-applicant'" [model]="data" [showTitle]="false"
        [isEditMode]="true" (result)="onSubmit($event)">
    </app-dyn-form>

</div>
<div class="buttons">
    <mat-spinner *ngIf="isLoading" [diameter]="30" color="primary"></mat-spinner>
    <button mat-raised-button class="save-button" color="primary" [disabled]="isLoading" mat-button
        (click)="save()">SAVE</button>
    <button mat-raised-button mat-button (click)="close()">Cancel</button>
</div>