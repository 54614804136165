<div class="public-import-applicant mt-10" *ngIf="!hasApplied">
    <div class="main flex flex-col gap-8" style=" max-width: 1000px; margin: auto;">
        <img class="flex" src="assets/images/logo/capella-logo.png" style="width: 180px;">
        <div class="flex">
            <h1 style="font-size: revert;">
                Apply Now
            </h1>
        </div>
        <div id="reports" class="flex flex-col gap-2 ">
            <app-dyn-form #dynForm class="form" [key]="'import-applicant'" [model]="data" [showTitle]="false"
                [message]="'Thank you for your application.'" [isEditMode]="true" (result)="onSubmit($event)">
            </app-dyn-form>
            <div style="display: flex;">
                <mat-spinner *ngIf="isLoading" [diameter]="30" color="primary"></mat-spinner>
                <button class="m-r-auto" mat-raised-button color="primary" [disabled]="isLoading" mat-button
                    (click)="save()">
                    Submit
                </button>
            </div>
        </div>
    </div>
</div>
<div class="user-msg-container" *ngIf="hasApplied">
    <section class="login-main-wrapper">
        <mat-icon class="check-circle-icon">check_circle</mat-icon>
        <div style="justify-content: center">
            <p style="font-weight: bold; font: 18px">THANK YOU</p>
        </div>
        <P style="font: 18px">Form has been successfully submitted</P>
    </section>
</div>