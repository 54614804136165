import { Component, Input } from '@angular/core';
import { ITaskResponse } from '@app/workflow/common/models/task-response';
import { RequestsService } from '@app/workflow/common/services/requests.service';

@Component({
    // tslint:disable-next-line:component-selector
    selector: 'app-task-response-chain',
    templateUrl: './response-chain.component.html',
    styleUrls: ['./response-chain.component.scss'],
    providers: [
        RequestsService
    ]
})
export class TaskResponseChainComponent {
    public isLoading = false;
    @Input() public set wfRefId(val: string) {
        this._wfRefId = val;
        this.loadWFRelatedTasks(this.appId, this.wfRefId);
    }

    @Input() public set appId(val: string) {
        if (val) {
            this._appId = val;
            this.loadWFRelatedTasks(this.appId, this.wfRefId);
        }
    }

    public get wfRefId(): string { return this._wfRefId; }
    private _wfRefId;


    public get appId(): string { return this._appId; }
    private _appId;

    public taskResponseChain: ITaskResponse[];

    constructor(private requestsService: RequestsService) {
    }

    public async loadWFRelatedTasks(appId: string, wfReferenceId: string) {
        if (appId && wfReferenceId) {
            this.isLoading = true;
            this.taskResponseChain = await this.requestsService.loadWFRelatedTasks(appId, wfReferenceId);
            this.isLoading = false;
        }
    }

    public getFormattedTime(date) {
        const hoursOffset = new Date().getTimezoneOffset() / (-60);
        const dt = new Date(date);
        dt.setHours(dt.getHours() + hoursOffset);
        return (dt);

    }
}
